import React from 'react';
import NavBarList from '../../docsearch/components/NavBarList'
import Footer from './Footer';

import { useLanguage } from './LanguageContext.js';
import { useNavigate } from 'react-router-dom';

import './AboutUs.css';
import sitelogo from "../../../../src/images/arztme_logo.webp"; 

const AboutUs = () => {
  const navigate = useNavigate();

  const { langStrings } = useLanguage();
  const ST = langStrings.ABOUTUSPAGE;

  const NavigationHelper = (path)=>{
    navigate(path);
  }
  
  return (
    <div className='bg-light'>
    <NavBarList />
    <div className='container-fluid mt-4 mb-5'>
      <div className="d-flex justify-content-center align-items-center flex-grow-1 my-4  bg-light">
        <div className="container p-5 rounded shadow bg-white text-dark" style={{ width: '80%', height: 'auto',marginTop:'10vh'}}>
          <h2 className='text-center mb-5'>{ST.label}</h2>
        </div>
      </div>
    </div>
    <div style={{ color: 'black' }} className="container mt-5 pb-0">
      <div className="row ">
        <div className="col-md-6  d-flex justify-content-start align-items-end custom-mine">
          <div className="bottom-container">
            <div className="semicircle-background"></div>
            <img src="Handphone.png" alt="Arztme App on Phone" className="phone-image" />
          </div>
        </div>
        <div className="col-md-6 text-center text-md-start align-items-center p-4 z-1">
          <h2 className='mb-4'>{ST.label1}<br />{ST.label2}</h2>
          <p className='mb-4'>{ST.label3}</p>
          <button className="btn btn-orange px-4 py-2 fw-bold mb-4"  onClick={()=>NavigationHelper('/contact-us')}>{ST.label4}</button>
          <div className="">
            <img src={sitelogo} alt="Arztme Logo" height='130' width='220' />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default AboutUs;
