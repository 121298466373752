import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { englishStrings, germanStrings } from '../../../strings/index.js';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('German');
    const [langStrings, setLangStrings] = useState(germanStrings);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [isVerified,setisVerified] = useState('');
    const [isAccepting,setIsAccepting] = useState('');
    const [isInsurance,setInsurance] = useState('');
    useEffect(() => {
        const cookieValSet = Cookies.get('languageSelect');
        if (cookieValSet) {
            setLanguage(cookieValSet);
            setLangStrings(cookieValSet === 'German' ? germanStrings : englishStrings);
        }
    }, []);

    const toggleLanguage = () => {
        const newLanguage = language === 'English' ? 'German' : 'English';
        setLanguage(newLanguage);
        setLangStrings(newLanguage === 'German' ? germanStrings : englishStrings);
        if(localStorage.getItem('cookieConsent') == 'accepted'){
            Cookies.set('languageSelect', newLanguage, { expires: 1 });
        }
    };

    const updateLanguageFilter = (language) => {
        setSelectedLanguage(language);
    };

    const updateVerifiedFilter = (Flag)=>{
        setisVerified(Flag);
    }

    const updateAcceptingFilter = (Flag)=>{
        setIsAccepting(Flag);
    }

    const updateInsuranceFilter = (flag) =>{
        setInsurance(flag);
    }

    return (
        <LanguageContext.Provider value={{ language, langStrings, toggleLanguage,selectedLanguage,updateLanguageFilter,isVerified,updateVerifiedFilter,isAccepting,updateAcceptingFilter,isInsurance,updateInsuranceFilter}}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
