import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import './ProfileContent.css';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { useLanguage } from '../../../homepage/components/LanguageContext';
import { useAuthUser } from 'react-auth-kit';
import { toast } from 'sonner';

const Titleoptions = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'Dr.', label: 'Dr.' },
];

const TitleoptionsDe = [
  { value: 'Mr.', label: 'Herr.' },
  { value: 'Mrs.', label: 'Herrin.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'Dr.', label: 'Dr.' },
];

const BasicInfo = () => {
  const { user, docLocation, setUser } = useOutletContext();
  const [firstname, setFirstname] = useState(user?.firstName ? user.firstName : '')
  const [lastname, setLastname] = useState(user?.lastname ? user.lastname : '');
  const [profileName, setProfilename] = useState(user?.profileName || '');
  const [title, setTitle] = useState(Titleoptions.find(option => option.value === user?.title));
  const [professionOptions, setProfessionOptions] = useState([]);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [specialistOptions, setSpecialistOptions] = useState([]);
  const [profession, setProfession] = useState(null);
  const [speciality, setSpeciality] = useState([]);
  const [specialist, setSpecialist] = useState([]);
  const [languagesSpoken, setLanguagesSpoken] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const { language, langStrings } = useLanguage();
  const authUser = useAuthUser();
  const ST = langStrings.DOCEDIT.BASICINFO;

  const callUpdatedUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor/id?doctorId=${user.id}&enrich=true`
      );
      if (response.status === 200) {
        setUser(response.data);
      }
    } catch (error) {
      console.error('Error fetching updated user:', error);
    }
  };
  
  useEffect(() => {
    setFirstname(user?.firstname);
    setTitle(Titleoptions.find(option => option.value === user?.title));
    setLastname(user?.lastname);
    setProfilename(user?.profileName);
    
    const fetchData = async () => {
      try {
        const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
        const token = authUser()?.authToken || '';
        const response = await axios.get(process.env.REACT_APP_JAVA_BASE_URL + '/template', {
          headers: {
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          },
        });
        if(user){
          const professionTieredData = response.data.professionTieredData;

          const profOptions = professionTieredData.map(prof => ({
            value: prof.id.toString(),
            label: language === 'English' ? prof.name : prof.nameDe,
            specialities: prof.specialities
          }));
          setProfessionOptions(profOptions);

          if (user.profession) {
            const professionId = user.profession.split(',')[0];
            const initialProfession = profOptions.find(option => 
              option.value === professionId
            );
            setProfession(initialProfession);

            if (initialProfession) {
              const newSpecialityOptions = initialProfession.specialities.map(spec => ({
                value: spec.id.toString(),
                label: language === 'English' ? spec.name : spec.nameDe,
                specialists: spec.specialists
              }));
              setSpecialityOptions(newSpecialityOptions);
            }
          }

          if (user.speciality) {
            const specialityIds = user.speciality.split(',');
            const initialSpecialities = specialityOptions.filter(option => 
              specialityIds.includes(option.value)
            );
            setSpeciality(initialSpecialities);

            const relevantSpecialities = specialityOptions.filter(spec => 
              specialityIds.includes(spec.value)
            );

            const allSpecialists = relevantSpecialities
              .flatMap(spec => spec.specialists || [])
              .map(spec => ({
                value: spec.id.toString(),
                label: language === 'English' ? spec.name : 
                  (user.gender === 'MALE' ? spec.nameMaleDe : spec.nameFemaleDe)
              }));
            setSpecialistOptions(allSpecialists);

            if (user.specialist) {
              const specialistIds = user.specialist.split(',');
              const initialSpecialists = allSpecialists.filter(option => 
                specialistIds.includes(option.value)
              );
              setSpecialist(initialSpecialists);
            }
          }

          const languagesFromApi = response.data.language.map(lang => ({
            value: lang.id.toString(),
            label: lang.name
          }));
          setLanguageOptions(languagesFromApi);
          
          if (user.languagesSpoken) {
            const userLanguageIds = user.languagesSpoken.split(',');
            const initialLanguages = languagesFromApi.filter(option => 
              userLanguageIds.includes(option.value)
            );
            setLanguagesSpoken(initialLanguages);
          }
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user, language]);

  const handleProfessionChange = (selectedOption) => {
    setProfession(selectedOption);
    if (selectedOption) {
      const newSpecialityOptions = selectedOption.specialities.map(spec => ({
        value: spec.id.toString(),
        label: language === 'English' ? spec.name : spec.nameDe,
        specialists: spec.specialists
      }));
      setSpecialityOptions(newSpecialityOptions);
      setSpeciality([]);
      setSpecialist([]);
    } else {
      setSpecialityOptions([]);
      setSpeciality([]);
      setSpecialist([]);
    }
  };

  const handleSpecialityChange = (selectedOptions) => {
    setSpeciality(selectedOptions || []);
    if (selectedOptions && selectedOptions.length > 0) {
      const newSpecialistOptions = selectedOptions.flatMap(spec => 
        (spec.specialists || []).map(specialist => ({
          value: specialist.id.toString(),
          label: language === 'English' ? specialist.name : 
            (user.gender === 'MALE' ? specialist.nameMaleDe : specialist.nameFemaleDe)
        }))
      );
      setSpecialistOptions(newSpecialistOptions);
    } else {
      setSpecialistOptions([]);
      setSpecialist([]);
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const updatedData = {
      firstname,
      lastname,
      profileName,
      title: title?.value || user.title,
      profession: profession ? profession.value : '',
      speciality: speciality.map(s => s.value).join(','),
      specialist: specialist.map(s => s.value).join(','),
      languagesSpoken: languagesSpoken.map(l => l.value).join(','),
    };
   
    try {
      toast.info(ST.UpdateInProccess, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });

      const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      const response = await fetch(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          },
          body: JSON.stringify(updatedData)
        }
      );
      
      setTimeout(async() => {
        if (response.status === 200) {
          toast.success(ST.DetailsUpdated, {
            position: 'top-center',
            duration: 3000,
            style: {
              fontSize: '15px', 
              padding: '10px 20px', 
              minWidth: '200px',
            },
          });
        }
        await callUpdatedUser();
      }, 300);
    } catch (error) {
      toast.warning(ST.FailedToUpdate, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      console.error('Error updating doctor data:', error);
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSaveChanges}>
        <Form.Group className="mb-3" controlId="formtitle">
          <Form.Label>{ST.label}</Form.Label>
          <Select
            options={language === 'English' ? Titleoptions : TitleoptionsDe}
            value={title}
            onChange={setTitle}
            placeholder="Select Your Title"
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', 
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                fontSize: '1rem',
                width: '100%', 
                paddingRight: '0.75rem', 
                boxShadow: 'none', 
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', 
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', 
                color: '#495057', 
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', 
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', 
                color: state.isSelected ? 'white' : '#495057', 
              }),
              singleValue: (base) => ({
                ...base,
                fontSize: '1rem',
                color: '#495057',
              }),
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formfirstname">
          <Form.Label>{ST.label1}</Form.Label>
          <Form.Control
            type="text"
            value={firstname}
            placeholder="Enter first name"
            onChange={(e) => setFirstname(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formlastname">
          <Form.Label>{ST.label2}</Form.Label>
          <Form.Control
            type="text"
            value={lastname}
            placeholder="Enter last name"
            onChange={(e) => setLastname(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formprofilename">
          <Form.Label>{ST.ProfileName}</Form.Label>
          <Form.Control
            type="text"
            value={profileName}
            placeholder="Enter Name You want to Display"
            onChange={(e) => setProfilename(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formprofession">
          <Form.Label>{ST.label3}</Form.Label>
          <Select
            value={profession}
            options={professionOptions}
            onChange={handleProfessionChange}
            placeholder="Select profession"
            isClearable
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', 
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                fontSize: '1rem',
                width: '100%', 
                paddingRight: '0.75rem', 
                boxShadow: 'none', 
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', 
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', 
                color: '#495057', 
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', 
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', 
                color: state.isSelected ? 'white' : '#495057', 
              }),
              singleValue: (base) => ({
                ...base,
                fontSize: '1rem',
                color: '#495057',
              }),
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formspeciality">
          <Form.Label>{ST.label4}</Form.Label>
          <Select
            value={speciality}
            options={specialityOptions}
            isMulti
            onChange={handleSpecialityChange}
            placeholder="Select specialities"
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', 
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                fontSize: '1rem',
                width: '100%', 
                
                paddingRight: '0.75rem', 
                boxShadow: 'none', 
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', 
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', 
                color: '#495057', 
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', 
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', 
                color: state.isSelected ? 'white' : '#495057', 
               
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef', 
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem', 
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057', 
                ':hover': {
                  backgroundColor: '#ced4da', 
                  color: 'black',
                },
              }),
            }}

          />
        </Form.Group>

        {specialistOptions.length > 0 && (
          <Form.Group className="mb-3" controlId="formspecialist">
            <Form.Label>Specialist Type</Form.Label>
            <Select
              value={specialist}
              options={specialistOptions}
              isMulti
              onChange={setSpecialist}
              placeholder="Select specialist types"
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', 
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', 
                  fontSize: '1rem',
                  width: '100%', 
                  
                  paddingRight: '0.75rem', 
                  boxShadow: 'none', 
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', 
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', 
                  color: '#495057', 
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000,
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', 
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', 
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', 
                  color: state.isSelected ? 'white' : '#495057', 
                 
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', 
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', 
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', 
                  ':hover': {
                    backgroundColor: '#ced4da', 
                    color: 'black',
                  },
                }),
              }}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="formlanguage">
          <Form.Label>{ST.label5}</Form.Label>
          <Select
            value={languagesSpoken}
            options={languageOptions}
            isMulti
            onChange={setLanguagesSpoken}
            placeholder="Select languages"
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', 
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                fontSize: '1rem',
                width: '100%', 
                
                paddingRight: '0.75rem', 
                boxShadow: 'none', 
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', 
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem', 
                color: '#495057', 
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem', 
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', 
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white', 
                color: state.isSelected ? 'white' : '#495057', 
               
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef', 
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem', 
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057', 
                ':hover': {
                  backgroundColor: '#ced4da', 
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>

        <Button style={{backgroundColor:'#f35b2a'}} type="submit">
          {ST.label6}
        </Button>
      </Form>
    </div>
  );
};

export default BasicInfo;