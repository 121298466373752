import React from 'react';
import './ImageScroll.css';

const ImageScroll = ({ user }) => {
  console.log("Sarthak bhai ye raha data dekh lo", user);
  const Images = user?.prefixedBgImageList || [];
  console.log("Images:", Images);
  const limitedImages = Images.slice(0, 3);

  return (
    <div className="image-scroll-container">
      {Images.length > 0 ? (
        <div className="image-scroll-wrapper">
          {limitedImages.map((image, index) => (
            <img
              key={image.id} 
              src={image.mediaLinkAbs}
              alt={`Image ${index + 1}`}
              className="scroll-image"
            />
          ))}
        </div>
      ) : (
        <div className="image-scroll-wrapper">
          <img src="/images.png" alt="Image 1" className="scroll-image" />
          <img src="/images 1.png" alt="Image 2" className="scroll-image" />
          <img src="/images 2.png" alt="Image 3" className="scroll-image" />
        </div>
      )}
    </div>
  );
};

export default ImageScroll;