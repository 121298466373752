import './App.css';
import './Docfinder.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { LanguageProvider } from './features_new/homepage/components/LanguageContext';
import { AuthProvider, RequireAuth } from "react-auth-kit";
import HamBurg from './features_new/homepage/components/HamBurg';
import ScrollToTop from './features_new/docsearch/components/ScrollToTop';
import { Toaster} from 'sonner'
import UserLoginPage from './pages/UserLogin';
import HomepageNew from './pages_new/HomepageNew';
import SearchNew from './pages_new/SearchNew';
import UserRegisterPageNew from './pages_new/UserRegisterPageNew'
import DoctorRegisterPageNew from './pages_new/DoctorRegisterPageNew'
import AreYouDoc from './features_new/homepage/components/AreYouDoc';
import DocProfileParent from './features_new/docsearch/components/DocProfileParent';
import ProfilePage from './features_new/docsearch/components/edit_pages/ProfilePage';
import BasicInfo from './features_new/docsearch/components/edit_pages/BasicInfo'
import ContactInfo from './features_new/docsearch/components/edit_pages/ContactInfo'
import AboutInfo from './features_new/docsearch/components/edit_pages/AboutInfo'
import EducationInfo from './features_new/docsearch/components/edit_pages/EducationInfo'
import InsuranceInfo from './features_new/docsearch/components/edit_pages/InsuranceInfo'
import OpeningTimes from './features_new/docsearch/components/edit_pages/OpeningTimes'
import SocialInfo from './features_new/docsearch/components/edit_pages/SocialInfo'
import ContactUs from './features_new/homepage/components/Contact';
import ForgotPassword from './features_new/homepage/components/ForgotPassword';
import Impressium from './features_new/homepage/components/Impressium';
import AboutUs from './features_new/homepage/components/AboutUs';
import ResetPassword from './features_new/homepage/components/ResetPassword';
import Careers from './features_new/homepage/components/Careers';
import Marketting from './features_new/homepage/components/Marketting';
import CookieManagement from './features_new/homepage/components/CookieManagement';
import CookieConsent from './features_new/homepage/components/CookieConsent';
import { useEffect, useState } from 'react';
import BackGroundImages from './features_new/docsearch/components/edit_pages/BackGroundImages';
import { LoaderProvider } from './common_components/LoaderContext';
import MapInfo from './features_new/docsearch/components/edit_pages/MapInfo';

function App() {
  const [consentGiven, setConsentGiven] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const savedConsent = localStorage.getItem('cookieConsent');
    if (!savedConsent) {
      setShowPopup(true);  
    } else {
      setConsentGiven(savedConsent === 'accepted');
    }
  }, []);

  const handleAccept = () => {
    setConsentGiven(true);
    localStorage.setItem('cookieConsent', 'accepted');
    setShowPopup(false);
  };

  const handleDecline = () => {
    setConsentGiven(false);
    localStorage.setItem('cookieConsent', 'declined');
    setShowPopup(false);
  };
  return (
    <LoaderProvider>
    <LanguageProvider>
    <AuthProvider
          authType={"cookie"}
          authName={"_LOLZZ"}
          cookieDomain={window.location.hostname}
          cookieSecure={false}
          sameSite={"Strict"}
        >
      {showPopup && (
        <CookieConsent onAccept={handleAccept} onDecline={handleDecline} />
      )}
      <Toaster richColors />
      <Router>
      <ScrollToTop /> 
        <div class="routes" id="routes-div">
          <Routes>
            <Route exact path="/user-login" element={<UserLoginPage />} />
            <Route exact path="/" element={<HomepageNew />} />
            <Route exact path="/new-search" element={<SearchNew />} />
            <Route exact path="/new-user-register" element={<UserRegisterPageNew />} />
            <Route exact path="/new-doctor-register" element={<DoctorRegisterPageNew />} />
            <Route exact path="/new-are-you-a-doctor" element={<AreYouDoc />} />
            <Route exact path="/home-sign-up" element={<HamBurg />} />
            <Route exact path='/doctor-profile/:id' element={<DocProfileParent />} />
            <Route exact path='/contact-us' element={<ContactUs/>} />
            <Route exact path='/forgot-password' element={<ForgotPassword/>} />
            <Route exact path='/about-us' element={<AboutUs/>} /> 
            <Route exact path='/impressium' element={<Impressium/>} />
            <Route exact path='/reset-password' element={<ResetPassword/>} />
            <Route exact path='/marketing' element={<Marketting/>} />
            <Route exact path='/careers' element={<Careers/>} />
            <Route exact path='/cookie-management' element={<CookieManagement/>} />
            <Route path="/edit-doctor-profile/:id" element={<ProfilePage />}>
              <Route path="" element={<BasicInfo />} />
              <Route path="contact-info" element={<ContactInfo />} />
              <Route path="about-info" element={<AboutInfo />} />
              <Route path="insurance-info" element={<InsuranceInfo />} />
              <Route path="education-info" element={<EducationInfo />} />
              <Route path="opening-times" element={<OpeningTimes />} />
              <Route path="social-info" element={<SocialInfo />} />
              <Route path="background-images" element={<BackGroundImages />} />
              <Route path="map-info" element={<MapInfo />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
    </LanguageProvider>
    </LoaderProvider>
  );
}

export default App;