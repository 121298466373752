let _ = require('lodash');

var methodsObj = {
  getQueryString: function(data) {
    var str = "?";
    var pairs = [];
    Object.keys(data).forEach(function inner(each) {
        if(data[each] != null && (data[each] || data[each] == 0 || ((typeof(data[each]) == "string") && data[each] != "")))
          pairs.push(each + "=" + data[each]);

    });
    str = str + pairs.join("&");
    if(pairs.length == 0)
      str = ''; 
    return str;
  },

  getStringFromEntityIdAndTemplateData: function (csv, arrayValues) {
    if (csv && arrayValues) {
      let actualAr = csv.split(',').map(item => item.trim());
      let parsedStr = [];
      actualAr.forEach(function fn(item) {
        arrayValues.find(function fn1(lang) {
          if (lang.id == item) {
            parsedStr.push(lang.name);
            return lang.name;
          }
        });
      });
      let ans = parsedStr.join(', ');
      return ans;
    } else {
      return '';
    }
  },
  myCustomFormJsonParser: function (arrayEntries) {
    let output = {};
    arrayEntries.forEach(function fn(each) {
      if (_.has(output, each[0])) {
        output[each[0]] += ',' + each[1];
      } else {
        output[each[0]] = each[1];
      }
    })
    return output;
  },
  getDocLocAddressStr:function getDocLocAddressStr(loc, language) {
    if (!loc) {
        return "Location information not available";
    }
    
    let ar = [];
    
    if (loc.street && loc.address) {
        ar.push(`${loc.street} ${loc.address}`);
    } else {
        if (loc.street) ar.push(loc.street);
        if (loc.address) ar.push(loc.address);
    }
    
    if (loc.area) ar.push(loc.area);
    
    if (loc.cityStr && language === 'English') {
        ar.push(loc.cityStr);
    } else if (loc.cityStrDe && language !== 'English') {
        ar.push(loc.cityStrDe);
    }
    
    return ar.join(', ').replace(/,\s*,/g, ',');
},
  

  getLanguageString: function getLanguageString(defaultKey, GermanKey, language) {
    return language == 'English' ? defaultKey : GermanKey;
  },


  getLanguageGenderString: function getLanguageGenderString(defaultKey, GermanMaleKey, GermanFemaleKey, language,gender) {
    if(language == 'English'){
      return defaultKey;
    }
    if(gender == 'FEMALE'){
      return GermanFemaleKey;
    }
    return GermanMaleKey;
  },

  getDocLocAddressStr1: function getDocLocAddressStr1(loc,language){
    if (!loc) {
      return "Location information not available";
  }
    let str='';
    let ar = [];
    if(loc.street && loc.street.trim() != "")
      ar.push(loc.street.trim());
    if(loc.area && loc.area.trim() != "")
        ar.push(loc.area.trim());
    if(loc.cityStr && loc.cityStr.trim() != "")
        ar.push(loc.cityStr.trim());

    str= ar.join(', ').replace(/,\s*,/g, ',');
    return str;
  },

  getEmptyTemplateData : function(){
    var tData = {};
    ["country",
      "state",
      "city",
      "countryData",
      "language",
      "speciality",
      "profession",
      "specialist",
      "professionTieredData",
      "countryMap",
      "stateMap",
      "cityMap",
      "languageMap",
      "professionMap"].forEach(function(each){
        tData[each] = [];
      });
      return tData;
  },
  getCurrentDate: function(separator=''){
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${date}${'-'}${month<10?`0${month}`:`${month}`}${'-'}${year}`
  },


  getCroppedImg: function(imageSrc, pixelCrop) {
    const image = new Image();
    image.src = imageSrc;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    return new Promise((resolve, reject) => {
      image.onload = () => {
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );

        canvas.toBlob(blob => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          resolve(blob);
        }, 'image/jpeg');
      };

      image.onerror = reject;
    });
  }
}



module.exports = methodsObj;