import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios'; 
import { useLanguage } from '../../../homepage/components/LanguageContext';
import { useAuthUser } from 'react-auth-kit';
import { toast } from 'sonner';

const AboutInfo = () => {
  const { user,setUser } = useOutletContext();
  console.log(user)
  const [about, setAbout] = useState(user?.about || "");
  const [aboutDe, setGermanAbout] = useState(user?.aboutDe || "");
  const [isTranslated, setIsTranslated] = useState(false);
  const [isTranslationApproved, setIsTranslationApproved] = useState(false);
  const [newPatients, setNewPatients] = useState(user.info?.['1_a_n_p'] || "No");
  const [waitingTime, setWaitingTime] = useState(user.info?.['2_a_w_t'] || 0);
  const [translationError, setTranslationError] = useState(null);
  const { langStrings } = useLanguage();
  const ST = langStrings.DOCEDIT.ABOUTINFO;
  const {language} = useLanguage();
  const authUser = useAuthUser();
  const patientOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];
  useEffect(()=>{
    if(user){
      setAbout(user.about);
      setGermanAbout(user.aboutDe);
      setIsTranslated(user.aboutDe? true : false);
      setIsTranslationApproved(user.aboutDe? user.isTranslationApproved : false);
      setNewPatients(user?.info['1_a_n_p']);
      setWaitingTime(user?.info['2_a_w_t']);
    }
  },[user]);
  const translateToGerman = async () => {  
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;
      const response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${apiKey}&q=${encodeURIComponent(about)}&target=de`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      
      const translateData = await response.json();
      const translatedText = translateData.data.translations[0].translatedText;
      
      setGermanAbout(translatedText);
      setTranslationError(null);
      setIsTranslated(true);
      setIsTranslationApproved(false);
    } catch (error) {
      console.error('Translation error:', error);
      setTranslationError('Failed to translate text. Please try again.');
      setGermanAbout("");
      setIsTranslated(false);
    }
  };

  const approveTranslation = () => {
    if (aboutDe.trim()) {
      setIsTranslationApproved(true);
    } else {
      alert('Please edit the German translation before approving.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ((about != user.about && !isTranslationApproved) || (aboutDe == "" && !isTranslationApproved)) {
      toast.warning(ST.TranslationApproval, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      return;
    }

    const updatedData = {
      about,
      aboutDe, 
      info: {
        '1_a_n_p': newPatients, 
        '2_a_w_t': waitingTime, 
      }
    };

    try {
      toast.info(ST.UpdateInProccess, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken ||  ''
      const response = await axios.put(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`, 
        updatedData, 
        {
          headers: {
            'Content-Type': 'application/json',
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          }
        }
      );
      const data = await response.data;
      setTimeout(() => {
      if(response.status == 200){
        toast.success(ST.DetailsUpdated, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        setUser(data);
      }
    }, 300);
    } catch (error) {
      console.error('Error updating data:', error);
      toast.warning(ST.FailedToUpdate, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formAbout">
          <Form.Label>{ST.label}</Form.Label>
          <Form.Control
            as="textarea"
            rows={8} 
            value={about}
            placeholder="Tell us about yourself"
            onChange={(e) => {
              setAbout(e.target.value);
              setIsTranslated(false);
              setIsTranslationApproved(false);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formTranslation">
          {!isTranslated && (
            <Button 
              variant="secondary" 
              onClick={translateToGerman} 
              className="mb-2"
              disabled={!about?.trim()}
            >
              Translate to German
            </Button>
          )}
          
          {translationError && (
            <Alert variant="danger">{translationError}</Alert>
          )}

          {isTranslated && (
            <>
              <Form.Label>Approve Translation</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                value={aboutDe}
                placeholder="Edit the German translation"
                onChange={(e) => {
                  setGermanAbout(e.target.value);
                  setIsTranslationApproved(false);
                }}
              />

              <Button 
                variant="success" 
                onClick={approveTranslation} 
                className="mt-2"
              >
                Approve Translation
              </Button>

              {isTranslationApproved && (
                <Alert variant="success" className="mt-2">
                  Translation Approved ✓
                </Alert>
              )}
            </>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formNewPatients">
          <Form.Label>{ST.label1}</Form.Label>
          <Select 
            value={patientOptions.find(option => option.value === newPatients)}
            onChange={(option) => setNewPatients(option.value)}
            options={patientOptions}
            placeholder="New Patient Acceptance"
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)', 
                border: '1px solid #ced4da', 
                borderRadius: '0.375rem', 
                fontSize: '1rem', 
                width: '100%', 
                paddingRight: '0.75rem',
                boxShadow: 'none', 
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem',
                color: '#495057',
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white',
                color: state.isSelected ? 'white' : '#495057',
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef',
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem',
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057',
                ':hover': {
                  backgroundColor: '#ced4da',
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formWaitingTime">
          <Form.Label>{ST.label2}</Form.Label>
          <Form.Control
            type="number"
            value={waitingTime}
            placeholder="Enter average waiting time (in Days)"
            onChange={(e) => setWaitingTime(e.target.value)}
          />
        </Form.Group>

        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label3}
        </Button>
      </Form>
    </div>
  );
};

export default AboutInfo;
