import React, { useState, useRef, useCallback, useEffect } from 'react';
import { PlusIcon, TrashIcon, GripVertical } from 'lucide-react';
import Cropper from 'react-easy-crop';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { useLanguage } from '../../../homepage/components/LanguageContext';
import { useAuthUser } from 'react-auth-kit';
import { toast } from 'sonner';

const BackgroundImageUploader = () => {
  const { user } = useOutletContext();
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const MAX_IMAGES = 10;
  const { language, langStrings } = useLanguage();
  const authUser = useAuthUser();
  const [imageToCrop, setImageToCrop] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const ST = langStrings.DOCEDIT.BACKGROUNDIMAGES;

  const fetchBackgroundImages = useCallback(async () => {
    try {
      const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      const response = await axios.get(
        process.env.REACT_APP_JAVA_BASE_URL + `/doctor-media/doctorFiles/${user.id}`,
        {
          headers: {
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          },
        }
      );
      setBackgroundImages(
        response.data.map((img) => ({
          id: img.id,
          url: img.mediaLinkAbs,
          priority: img.priority,
        }))
      );
    } catch (error) {
      console.error('Error fetching images:', error);
      toast.error(ST.IMAGELOADINGFAILED);
    }
  }, [user.id, language]);

  useEffect(() => {
    fetchBackgroundImages();
  }, [fetchBackgroundImages]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = new Image();
    image.src = imageSrc;
    
    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
        
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      };
    });
  };

  const handleCropConfirm = async () => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      const croppedImage = await getCroppedImg(imageToCrop, croppedAreaPixels);
      const croppedImageFile = new File([croppedImage], 'cropped-background.jpg', {
        type: 'image/jpeg',
      });
      
      await uploadImageToAPI(croppedImageFile);
      setIsCropping(false);
      setImageToCrop(null);
      await fetchBackgroundImages();
    } catch (error) {
      console.error('Error cropping or uploading image:', error);
      toast.error(ST.IMAGEUPLOADINGFAILED);
    } finally {
      setIsProcessing(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const uploadImageToAPI = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append('File', file);
    
    try {
      toast.info(ST.IMAGEUPLOADING, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px',
          padding: '10px 20px',
          minWidth: '200px',
        },
      });

      const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      const response = await axios.post(
        process.env.REACT_APP_JAVA_BASE_URL + `/doctor-media/addFile?doctorId=${user.id}&priority=${backgroundImages.length + 1}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          },
        }
      );

      if (response.status === 200) {
        toast.success(ST.IMAGEUPLOADED, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px',
            padding: '10px 20px',
            minWidth: '200px',
          },
        });
      }
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error(ST.IMAGEUPLOADINGFAILED, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px',
          padding: '10px 20px',
          minWidth: '200px',
        },
      });
      throw error;
    } finally {
      setIsUploading(false);
    }
  };

  const deleteImage = async (id) => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      toast.info(ST.IMAGEDELETING, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px',
          padding: '10px 20px',
          minWidth: '200px',
        },
      });

      const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      const response = await axios.put(
        process.env.REACT_APP_JAVA_BASE_URL + `/doctor-media/updateFile/${user.id}/${id}`,
        { status: "0" },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          }
        }
      );

      if (response.status === 200) {
        toast.success(ST.IMAGEDELTED, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px',
            padding: '10px 20px',
            minWidth: '200px',
          },
        });
        await fetchBackgroundImages();
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error(ST.IMAGEDELETINGFAILED, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px',
          padding: '10px 20px',
          minWidth: '200px',
        },
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const updateImagePriority = async (imageId, newPriority) => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      await axios.put(
        process.env.REACT_APP_JAVA_BASE_URL + `/doctor-media/updateFile/${user.id}/${imageId}`,
        { priority: `${newPriority}` },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': token
          }
        }
      );
    } catch (error) {
      console.error('Error updating image priority:', error);
      toast.error('Failed to update priority');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileChange = async (event) => {
    if (isProcessing) return;

    const file = event.target.files[0];
    if (!file) return;

    const isImage = file.type.startsWith('image/');
    const isValidSize = file.size / 1024 / 1024 < 2;

    if (!isImage) {
      toast.error('Please select an image file');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    if (!isValidSize) {
      toast.error('Image must be smaller than 2MB');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    if (backgroundImages.length >= MAX_IMAGES) {
      toast.error(`You can only upload up to ${MAX_IMAGES} background images`);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    setImageToCrop(imageUrl);
    setIsCropping(true);
  };

  const handleDragStart = (e, index) => {
    if (isProcessing) return;
    setDraggedItem(backgroundImages[index]);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e, targetIndex) => {
    e.preventDefault();
    e.stopPropagation();

    if (isProcessing || !draggedItem) return;

    try {
      setIsProcessing(true);
      const newImages = [...backgroundImages];
      const draggedIndex = newImages.findIndex((img) => img.id === draggedItem.id);
      newImages.splice(draggedIndex, 1);
      newImages.splice(targetIndex, 0, draggedItem);

      setBackgroundImages(newImages);
      setDraggedItem(null);

      for (const [index, image] of newImages.entries()) {
        await updateImagePriority(image.id, index + 1);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const triggerFileInput = () => {
    if (isProcessing) return;
    
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  return (
    <div className="background-uploader-container">
      {isCropping && imageToCrop && (
        <div className="crop-modal">
          <div className="crop-container">
            <div className="cropper-container">
              <Cropper
                image={imageToCrop}
                crop={crop}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className="crop-controls">
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => setZoom(Number(e.target.value))}
                className="zoom-slider"
                disabled={isProcessing}
              />
              <div className="crop-buttons">
                <button 
                  onClick={() => {
                    setIsCropping(false);
                    setImageToCrop(null);
                    if (fileInputRef.current) {
                      fileInputRef.current.value = '';
                    }
                  }}
                  disabled={isProcessing}
                >
                  {ST.CANCEL}
                </button>
                <button 
                  onClick={handleCropConfirm}
                  disabled={isProcessing}
                >
                  {isProcessing ? ST.UPLOADING : ST.UPLOAD}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-3 shadow-sm p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">{ST.BACKGROUNDIMAGE}</h5>
          <span className="text-muted">
            {backgroundImages.length} / {MAX_IMAGES}
          </span>
        </div>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          className="d-none"
          disabled={isProcessing}
        />

        <div className="row g-3">
          {backgroundImages.map((image, index) => (
            <div
              key={image.id}
              className="col-4 position-relative"
              draggable={!isProcessing}
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
            >
              <div className="position-relative">
                <div className="position-absolute top-2 start-2 z-10 text-muted cursor-move">
                  <GripVertical size={20} />
                </div>
                <img
                  src={image.url}
                  alt={`Background ${index + 1}`}
                  className="img-fluid rounded-3 object-fit-cover"
                  style={{ height: '200px', width: '100%' }}
                />
                <button
                  onClick={() => deleteImage(image.id)}
                  className="btn btn-danger btn-sm position-absolute top-2 end-2 rounded-circle d-flex align-items-center justify-content-center"
                  style={{ width: '30px', height: '30px', padding: 0, zIndex: '1' }}
                  disabled={isProcessing}
                >
                  <TrashIcon size={16} />
                </button>
              </div>
            </div>
          ))}

          {backgroundImages.length < MAX_IMAGES && !isUploading && (
            <div className="col-4">
              <button
                onClick={triggerFileInput}
                className="btn btn-outline-secondary w-100 h-100 d-flex flex-column align-items-center justify-content-center rounded-3 border-dashed"
                style={{ minHeight: '200px', borderWidth: '2px', borderStyle: 'dashed' }}
              >
                <PlusIcon size={32} className="text-muted mb-2" />
                <span className="text-muted">{ST.UPLOAD}</span>
              </button>
            </div>
          )}

          {isUploading && (
            <div className="col-4 d-flex align-items-center justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackgroundImageUploader;