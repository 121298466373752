import { useState, useEffect } from 'react';
import utils from './../../../utils.js';
import { useParams, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Constants from './../../../Constants.js';
import queryString from 'query-string';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import UserRegisterViewNew from './UserRegisterViewNew.jsx';
import { toast } from 'sonner';
import { useLoader } from '../../../common_components/LoaderContext.js';
import { useLanguage } from '../../homepage/components/LanguageContext.js';

var _ = require('lodash');

export default function UserRegisterNew() {
    const {setIsLoading} = useLoader();
    useEffect(()=>{
      setIsLoading(false)
    },[])
    const {language} = useLanguage();
    const [searchTextTop, setSearchTextTop] = useState("Please register your details.");
    const templateApiUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_TEMPLATE_API_REL_URL
    const userRegistrationUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_REGISTRATION_REL_URL;
    const navigate = useNavigate();
    const gotToNewPage=()=>{
        navigate("/user-login");
    }
    async function handleUserRegisterFormSubmit(e) {
      e.preventDefault();
      const form = e.target;
      console.log(form);
      const formData = new FormData(form);
      console.log(formData);
      const formJson = Object.fromEntries(formData.entries());
      let payloadObj = payloadFromFormData(formJson);
      //setSearchTextTop("Registration submitted for you user. Please check your email for next steps.");
      let resp = await callRegisterHandler(userRegistrationUrl, payloadObj);
      if (resp.success) {
        toast.success('Welcome to Arztme', {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        // setSearchTextTop("Registration submitted. Please check your email for next steps.");
        gotToNewPage();
      } else {
        toast.warning('Oops Something Went Wrong', {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        // setSearchTextTop("Registration failed. Some issue. Please contact us or try again later");
        // alert("Registration failed. Some issue. Please contact us or try again later");
      }
  
    }
  
    function payloadFromFormData(form){
      let requestBody = {
        firstname: form.firstname,
        email: form.email,
        password: form.password
      };
  
      return requestBody;
    }
    
    function registrationSuccessFunc(){
      // let div = document.getElementById("user-registration-form-id")
      // div.replaceChildren();
  
    }
  
    async function callRegisterHandler(url, body) {
  
      let resp = await callRegisterApi(url, body);
      
      if (resp && resp.status === 200)
        return { success: true };
      else
        return { success: false };
  
    }
  
    const callRegisterApi = async (url, body) => {
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-type': 'application/json;',
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN':''
        },
      });
      let resp = await response;
      return resp;
    }
  
    function passwordShowClickHandler(){
      const passwordField = document.getElementById("password");
      const togglePassword = document.querySelector(".password-toggle-icon-span i");
        if (passwordField.type === "password") {
          passwordField.type = "text";
          togglePassword.classList.remove("zmdi-eye");
          togglePassword.classList.add("zmdi-eye-off");
        } else {
          passwordField.type = "password";
          togglePassword.classList.remove("zmdi-eye-off");
          togglePassword.classList.add("zmdi-eye");
        }
    }

    return (
        <div>
            <UserRegisterViewNew 
                handleUserRegisterFormSubmit = {handleUserRegisterFormSubmit}
            />
        </div>
    )
}