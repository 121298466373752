import React, { createContext, useState, useContext } from 'react';
import Loader from './Loader.js';

const LoaderContext = createContext({
  isLoading: false,
  setIsLoading: () => {}
});

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && <Loader />}
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);