import React, { Suspense, lazy } from 'react'

import englishFlag from "../../../../src/images/english_flag.svg";
import germanFlag from "../../../../src/images/german_flag.webp";
import sitelogo from "../../../../src/images/Arztme_Logo_Slogan.webp";
import '../../../css/Homepage.css';
import SearchBar from './SearchBarTry.jsx';
import Profiledropdown from './Profiledropdown.jsx';

const Citites = lazy(() => import("./Cities.jsx"));
const Filter = lazy(() => import("./Filters.jsx"));
const Footer = lazy(() => import("./Footer.jsx"));


const HomePageView = ({
    authUser,
    handleNavigation,
    isAuthenticated,
    isMobile,
    isTablet,
    karwaisko,
    language,
    ST,
    toggleLanguage,
    detectedLocation,
}) => {
  console.log(`dekho ye hai`,authUser())
  return (
    <div className="bg-light">
      <div  className="container">
      {isMobile ? 
            <>
              <img onClick={()=> handleNavigation('/home-sign-up')} src="hm.svg" className="img-thumbnail rounded float-left"
              style={{
                height:'30px',
                width:'30px',
                cursor:'pointer',
                padding:'0px',
              }} alt="Hamburger Logo" loading='eager' fetchPriority='high'  />
              <div
              onClick={toggleLanguage}
              className="rounded float-right "
              alt={`${language} flag`}
              style={{
                backgroundImage:`url(${language === 'English' ? 'ufs.svg' : 'afs.png'})`,
                marginTop:'2px',
                height:'25px',
                width:'30px',
                cursor:'pointer',
                borderRadius:'20px',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                loading:"eager",
                fetchpriority:"high"
              }}
            ></div>
          </>
             : null}
        <div className="container">

          <div className="d-flex align-items-center" style={{ height: '20%' }}>
        
            <div className={`col-12 col-md-6 col-lg-auto  ${isMobile ? 'text-center' : 'text-lg-start'}`}>
              <img loading="eager" fetchPriority='high' src={sitelogo} className="img-fluid imgCont" alt="site logo" />
            </div>

            <div className="contDiv ms-auto">
              {!isMobile && (
                <div className="navCont d-flex align-items-center">
                  {language === 'English' ? (
                    <img onClick={toggleLanguage} className="navImgEng" src={englishFlag} alt="English flag" />
                  ) : (
                    <img onClick={toggleLanguage} className="navImgGer" src={germanFlag} alt="German flag" />
                  )}

                  {isAuthenticated && authUser()?.isDoctor ? <></> : <div onClick={()=> handleNavigation('/new-are-you-a-doctor')} className="navContText "
                  data-content={language === 'English' ? 'Are you a doctor?' : 'Sind Sie Arzt?'}>
                    {ST.NAV_BAR_SECTION.DOCTOR_OR_THERAPIST_BTN.label}
                  </div> }
                 
                  {isAuthenticated && authUser()?.isUser ? (
                    <div className="ms-2"> 
                    <Profiledropdown
                    authUser={authUser} 
                    handleNavigation={handleNavigation}
                    karwaisko={karwaisko}
                    language={language}
                    ST={ST}
                    isAuthenticated={isAuthenticated}
                    />
                    </div>
                  ) : (
                    <div onClick={()=> handleNavigation('/user-login')} className="navContText2">
                      {ST.NAV_BAR_SECTION.LOGIN.label}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* words animations */}
        <div className="container">
          <div className="row d-flex pad-top">
            <div className="col">
              <div style={{ paddingBottom: '0px' }} className="d-flex justify-content-left algin-text-left">
                <p style={{ color: '#595959', marginRight: '10px' }} className="my-1 words1 d-flex font-weight-bold">
                  {ST.SEARCH_SECTION.HEADER.label}
                </p>
                {
                  window.innerWidth > 500 || language == 'English'?
                  <p style={{ color: "#7f7f7f" }} className="my-1 words1  font-weight-bold">
                    {ST.SEARCH_SECTION.HEADER.label1}
                  </p>:<></>
                }
                {
                  window.innerWidth > 700?
                  language == 'English'?
                  <div className="d-flex justify-content-left">
                    <p style={{ color: "#a6a6a6" }} className="my-1 words1  font-weight-bold">
                      {" "}{ST.SEARCH_SECTION.HEADER.label2}
                    </p>
                    <p style={{ color: "#bfbfbf" }} className="my-1 words1 font-weight-bold">
                      {ST.SEARCH_SECTION.HEADER.label3}
                    </p>
                  </div>:<></>:
                  <></>
                }
                {
                  window.innerWidth > 900 && language == 'German' ?
                    <div className="d-flex justify-content-left">
                      <p style={{ color: "#a6a6a6" }} className="my-1 words1  font-weight-bold">
                        {" "}{ST.SEARCH_SECTION.HEADER.label2}
                      </p>
                    </div>:<></>
                }
                </div>
                {
                  window.innerWidth < 700?
                    language == 'English' ?
                    <div className="d-flex justify-content-left">
                      <p style={{ color: "#a6a6a6" }} className="my-1 words1  font-weight-bold">
                        {" "}{ST.SEARCH_SECTION.HEADER.label2}
                      </p>
                      <p style={{ color: "#bfbfbf" }} className="my-1 words1 font-weight-bold">
                        {ST.SEARCH_SECTION.HEADER.label3}
                      </p>
                    </div>:
                    <div className="d-flex justify-content-left">
                      <p style={{ color: "#7f7f7f" }} className="my-1 words1  font-weight-bold">
        
                        {ST.SEARCH_SECTION.HEADER.label1}
                      </p>        
                    </div>
                    :<></>
                }
                <div className="words">
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label4}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label5}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label6}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label8}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label9}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label10}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label11}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label12}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label13}</span>
                  <span className="words1 bg-light font-weight-bold">{ST.SEARCH_SECTION.HEADER.label4}</span>
                </div>
            </div>
          </div>
        </div>
        <SearchBar  />
        <br />
        {!isTablet ?
        <>
        <Suspense fallback={<div style={{color:'black'}} >Loading Components...</div>}>
          <Filter  data={isMobile} />
          <Citites data={isMobile} />
        </Suspense>
        </>
        :<></>}
      </div>
      {isTablet?
        <>
        <Suspense fallback={<div style={{color:'black'}} >Loading Components...</div>}>
          <Filter data={isMobile} />
          <Citites data={isMobile} />
        </Suspense>
        </>
        :<></>}
        <Suspense fallback={<div style={{color:'black'}} >Loading Footer...</div>}>
          <Footer />
        </Suspense>
    </div>
  )
}

export default HomePageView


