import React, { useEffect, useState } from 'react';
import './CookieConsent.css';
import { useLanguage } from './LanguageContext';

const CookieConsent = ({ onAccept, onDecline }) => {
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.DOCEDIT.COOKIECONSENT;
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    onAccept();
    setShowConsent(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    onDecline();
    setShowConsent(false);
  };

  return (
    <div className='z-999'>
      <div className={`wrapper ${showConsent ? 'show' : ''}`}>
        <header>
          <i className="bx bx-cookie"></i>
          <h2>{ST.CookiesConsent}</h2>
        </header>
        <div className="data">
          <p>
            {ST.CookiesDescription}
          </p>
        </div>
        <div className="buttons">
          <button className="button fw-bold" id="acceptBtn" onClick={handleAccept}>
            {ST.Accept}
          </button>
          <button className="button fw-bold" id="declineBtn" onClick={handleDecline}>
            {ST.Decline}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
