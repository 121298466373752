import React, { useState, useEffect } from 'react';
import { IoSearch, IoLocation } from "react-icons/io5";
import Select from 'react-select';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';  
import debounce from 'lodash/debounce';  
import { useLanguage } from '../../homepage/components/LanguageContext';
import EditableSelect from './EditableSelect';
import utils from "../../../utils"
import { useAuthUser } from 'react-auth-kit';

const SearchBar = ({
  searchkeyEn,
  searchkeyDe,
  locationkeyEn,
  locationkeyDe
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language,langStrings} = useLanguage();
  const ST = langStrings.HOMEPAGE;
  const defaultSpecialtyOptions = [
    { value: 'Gynaecologist', label: 'Gynaecologist' },
    { value: 'General Practitioner', label: 'General Practitioner' },
    { value: 'Orthopaedist', label: 'Orthopaedist' },
    { value: 'Paediatrician', label: 'Paediatrician' },
    { value: 'Nephrologist', label: 'Nephrologist' },
    { value: 'Cardiologist', label: 'Cardiologist' },
    { value: 'Neurologist', label: 'Neurologist' },
    { value: 'Neurosurgeon', label: 'Neurosurgeon' },
  ]
  const authUser = useAuthUser();
  const [specialtyOptions, setSpecialtyOptions] = useState(defaultSpecialtyOptions);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectType,setSelectedType] = useState('');
  const [urlNikalo, UrlSetKaro] = useState(''); 
  const [placeholderText, setPlaceholderText] = useState('Searching for a Doctor or Therapist');
  const [specialtyInputValue, setSpecialtyInputValue] = useState(''); 
  const [locationInputValue, setLocationInputValue] = useState(''); 
  let SUGGEST_URL = process.env.REACT_APP_JAVA_BASE_URL+process.env.REACT_APP_SUGGEST_URL;
  const fetchDefaultSpecialtyOptions = async () => {
      try {
        const LanguageLocale = language == 'English' ? 'ENGLISH':'GERMAN';
        const response = await axios.get(`${SUGGEST_URL}`, {
          headers: {
            'X-LOCALE': `${LanguageLocale}`,
          },
        });
        const data = response.data && response.data.data ? response.data.data : [];
        const options = data.map(item => ({
          value: item.value,
          label: item.title,
          url: item.url,
        }));
        setSpecialtyOptions(options);
      } catch (error) {
        console.error('Error fetching default specialty options:', error);
        setSpecialtyOptions(defaultSpecialtyOptions);
      }
  };

  useEffect(()=>{
    fetchDefaultSpecialtyOptions();
  }, []);
  
  const fetchSpecialtyOptions = debounce((inputValue) => {
    if (inputValue) {
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=searchkey`)
        .then(response => {
          const data = response.data && response.data.data ? response.data.data : [];   
          if (data.length > 0) {
            const options = data.map(item => ({
              value: item.value,  
              label: item.title,
              url: item.url
            }));
            setSpecialtyOptions(options);
          } else {
            setSpecialtyOptions(defaultSpecialtyOptions);
          }
        })
        .catch(error => {
          console.error('Error fetching specialty options:', error);
          setSpecialtyOptions(defaultSpecialtyOptions); 
        });
    } else {
      fetchDefaultSpecialtyOptions();
    }
  }, 300);

  const fetchLocations = async () => {
    try {
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
     const token = authUser()?.authToken ||  ''
      const response = await axios.get(process.env.REACT_APP_JAVA_BASE_URL +  process.env.REACT_APP_LOCATION +  process.env.REACT_APP_CITY, {
        headers: {
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': token
        },
      });
      console.log(`location yaha par hai`,response);
      const locationData = response.data.map(city => ({
        value: utils.getLanguageString(city.name, city.nameDe, language),
        label: utils.getLanguageString(city.name, city.nameDe, language),
        id: city.id,
        state: city.stateId,
        originalName: city.name,
        originalNameDe: city.nameDe
      }));
      
      setLocationOptions(locationData);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setLocationOptions([
        { 
          value: utils.getLanguageString('Vienna', 'Wien', language),
          label: utils.getLanguageString('Vienna', 'Wien', language),
          originalName: 'Vienna',
          originalNameDe: 'Wien'
        },
        { 
          value: utils.getLanguageString('Klagenfurt', 'Klagenfurt', language),
          label: utils.getLanguageString('Klagenfurt', 'Klagenfurt', language),
          originalName: 'Klagenfurt',
          originalNameDe: 'Klagenfurt'
        },
        { 
          value: utils.getLanguageString('Wiener Neustadt', 'Wiener Neustadt', language),
          label: utils.getLanguageString('Wiener Neustadt', 'Wiener Neustadt', language),
          originalName: 'Wiener Neustadt',
          originalNameDe: 'Wiener Neustadt'
        }
      ]);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [language]);
  


  const [specialtyPlaceholder, setSpecialtyPlaceholder] = useState(ST.SEARCH_SECTION.INPUT.label3);
  const [locationPlaceholder, setLocationPlaceholder] = useState(ST.SEARCH_SECTION.INPUT.label1);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchKey = params.get('searchkey')
    //  || language == 'English' ? searchkeyEn : searchkeyDe;
    const speciality = params.get('speciality');
    const locationKey = params.get('locationkey')
    // || language == 'English' ? locationkeyEn : locationkeyDe;
    if (searchKey) {
      const matchedSpecialty = specialtyOptions.find(
        option => option.value.toLowerCase() === searchKey.toLowerCase()
      );
      
      if (matchedSpecialty) {
        setSelectedSpecialty(matchedSpecialty);
      } else {
        const newSpecialtyOption = { 
          value: searchKey, 
          label: searchKey 
        };
        setSpecialtyOptions(prev => [...prev, newSpecialtyOption]);
        setSelectedSpecialty(newSpecialtyOption);
      }
    }
    if (locationKey) {
      const matchedLocation = locationOptions.find(
        option => option.value.toLowerCase() === locationKey.toLowerCase()
      );
      
      if (matchedLocation) {
        setSelectedLocation(matchedLocation);
      } else {
        const newLocationOption = { 
          value: locationKey, 
          label: locationKey 
        };
        setLocationOptions(prev => [...prev, newLocationOption]);
        setSelectedLocation(newLocationOption);
      }
    }
  }, [location,language,window.innerWidth,searchkeyEn,searchkeyDe,locationkeyEn,locationkeyDe]);

  let Myurl = '/new-search?';
  const leJaoFiltersPar = (e) => {
    e.preventDefault(); 
    const params = new URLSearchParams(location.search);
    const searchKey = params.get('searchkey');
    const speciality = params.get('speciality');
    const locationKey = params.get('locationkey');
    const specialty = selectedSpecialty ? selectedSpecialty.value : "";
    const loc = selectedLocation ? selectedLocation.value : "";
    const language = params.get('language') || "";
    const acceptingNewPatient = params.get('acceptingNewPatient') || "";
    const insuranceGroup = params.get('insuranceGroup') || "";
    const isVerified = params.get('isVerified') || "";
    const url = urlNikalo;
    Myurl += `searchkey=${encodeURIComponent(specialty)}`
    Myurl += `&locationkey=${encodeURIComponent(loc)}`;
    Myurl += `&language=${encodeURIComponent(language)}`;
    Myurl += `&acceptingNewPatient=${encodeURIComponent(acceptingNewPatient)}`;
    Myurl += `&insuranceGroup=${encodeURIComponent(insuranceGroup)}`;
    Myurl += `&isVerified=${encodeURIComponent(isVerified)}`;
    // if(url){
    //   Myurl +=  `&${url}`;
    // }
    navigate(Myurl);
  }

  return (
    <div className="container">
      <div className="row justify-content-center ">
        <form className="row g-3 " method="get" style={{padding:'0px'}} >
          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoSearch style={{ zIndex: '99', position: 'absolute', left: '14px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <EditableSelect 
              value={selectedSpecialty}
              onInputChange={(newValue) => {
                setSpecialtyInputValue(newValue); 
                fetchSpecialtyOptions(newValue); 
              }}
              onChange={(option) => {
                if (!option) {
                  // Field cleared
                  setSpecialtyPlaceholder(ST.SEARCH_SECTION.INPUT.label3);
                  setSelectedSpecialty(null);
                  
                } else {
                  setSelectedSpecialty(option);
                  UrlSetKaro(option?.url || "");
                }
                // setSelectedSpecialty(option);
                // setSpecialtyInputValue(option ? option.label : '');
                // setSelectedType(option ? option.type : '');
                // UrlSetKaro(option ? option.url : '');
              }}
              options={specialtyOptions}
              placeholder={ST.SEARCH_SECTION.INPUT.label3}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 position-relative mb-3 no-gap">
            <IoLocation style={{ zIndex: '1', position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', fontSize: '35px', color: '#f35b2a' }} />
            <EditableSelect
              value={selectedLocation}
              options={locationOptions}
              onInputChange={(newValue) =>{
                setLocationInputValue(newValue);
                fetchLocations(newValue)
              }}
              onChange={(option) =>{
                if (!option) {
                  setLocationPlaceholder(ST.SEARCH_SECTION.INPUT.label1);
                  setSelectedLocation(null);
                  
                } else {
                  setSelectedLocation(option);
                  UrlSetKaro(option?.url || "");
                }
  
              }}
             
              placeholder={ST.SEARCH_SECTION.INPUT.label1}
            />
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 no-gap width-reduce">
            <button 
              onClick={leJaoFiltersPar}
              type="submit" 
              name="text-search-submit-btn" 
              className="btn btn-primary w-100"
              style={{ height: '60px', fontSize: '25px', fontWeight: 'bold', backgroundColor: '#f35b2a', border: 'solid', borderWidth: '1px', borderColor: 'black', borderRadius: '0px',boxShadow:'none' }}
            >
              {ST.SEARCH_SECTION.INPUT.label2}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchBar;


