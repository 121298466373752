import React, { useState, useRef,useEffect } from "react";
import Select, { components } from "react-select";

const Input = (props) => <components.Input {...props} isHidden={false} />;

const EditableSelect = ({ options, onInputChange, onChange, placeholder,value }) => {
  const [inputValue, setInputValue] = useState("");
  const selectRef = useRef();

  // Update input value when value prop changes
  useEffect(() => {
    if (value) {
      setInputValue(value.label);
    } else {
      setInputValue("");
    }
  }, [value]);

  const handleInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newValue);
      onInputChange(newValue);
    }
  };

  const handleChange = (option) => {
    if (!option) {
      setInputValue("");
      onChange(null);
    } else {
      // Value selected
      setInputValue(option.label);
      onChange(option);
    }
  };
  const DropdownIndicator = () => null;

  return (
    <Select
      ref={selectRef}
      options={options}
      noOptionsMessage={() => null}
      isClearable={true}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      placeholder={placeholder}
      controlShouldRenderValue={false}
      components={{ Input,DropdownIndicator }}
      styles={{
        control: (base) => ({
          ...base,
          height: '60px', 
          border: '1px solid black',
          borderRadius: '0px',
          width: '100%',
          fontSize:  window.innerWidth<430? '19px':'20px',
          paddingLeft: '40px',
          fontWeight: 'bold'

        }),
        input: (base) => ({
          ...base,
          fontSize: '20px',
          fontWeight: 'bold',
          zIndex:2,
        }),
        menu: (base) => ({
          ...base,
          opacity: 1,
          backgroundColor: 'white',
          color: 'black',
          zIndex:2,
        }),
        option: (base, state) => ({
          ...base,
          opacity: 1,
          color: state.isSelected ? 'white' : 'black',
          zIndex:2,
        }),
      }}
      
    />
  );
};

export default EditableSelect;
