import React, { useState } from 'react'
import './DocProfileView.css';
import ImageScroll from './ImageScroll';
import { SlSpeech } from "react-icons/sl";
import { ImLocation2 } from "react-icons/im";
import { MdPhoneInTalk } from "react-icons/md";
import { LuCalendarClock } from "react-icons/lu";
import ImageModal from './ImageModal';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FaUserShield } from "react-icons/fa";
import { LuClock3 } from "react-icons/lu";
import { FaUserGraduate } from "react-icons/fa";
import { LuFileSpreadsheet } from "react-icons/lu";
import { LiaPenSolid } from "react-icons/lia";
import { Form, Row, Col } from 'react-bootstrap';
import NavBar from './NavBar';
import ReviewCard from './ReviewCard';
import Footer from '../../homepage/components/Footer'
import { useLanguage } from '../../homepage/components/LanguageContext';
import utils from "../../../utils"

const Loader = () => (
  <div style={{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh', 
    backgroundColor: '#f4f4f4',
    flexDirection: 'column',
  }}>
    <Spinner 
      animation="border" 
      role="status" 
      style={{
        width: '80px', 
        height: '80px', 
        color: '#f35b2a', 
        borderWidth: '6px'
      }}
    >
    </Spinner>
    <span style={{
        width: '80px', 
        height: '80px', 
        color: '#f35b2a', 
      }} >Loading...</span>
  </div>
);

const DocProfileView = ({
  images,
  user,
  docLocation,
  docLocationMap,
  form,
  formData,
  errors,
  show,
  show1,
  handleInputChange,
  handleOpen,
  handleClose,
  handleOpen1,
  handleClose1,
  takeToEdit,
  handlePreSubmit,
  ReviewFormData,
  handleInputChange1,
  handleRatingChange1,
  handleSubmit1,
  dayWiseSlots,
  dayNames,
  convertToTime,
  reviews,
  isLastPage,
  loadMoreReviews,
  totalReviews,
  UserId,
  found,
  pageNumber,
  fetchReviews,
  isAuthenticated,
  takeToLogin,
  rating,
  ratingCount,
  rating1Count,
  rating2Count,
  rating3Count,
  rating4Count,
  rating5Count,
  isLoading,
}) =>{


const { language, langStrings, toggleLanguage } = useLanguage();
const ST = langStrings.DOCPROFILEPAGE;
const MT = langStrings.BOOKINGMODAL;

const ratingCounts = {
  rating1Count,
  rating2Count,
  rating3Count,
  rating4Count,
  rating5Count,
};

const getBarWidth = (count) => 
  (ratingCount > 0 ?
     Number(count / ratingCount) * 100 
     : 0);
console.log(user);
console.log(`sarthak yahan ye hai`,dayWiseSlots)
if (isLoading) {
  return <Loader />;
}


const dayNamesDe = ['Mon', 'Dien', 'Mitt', 'Donn', 'Frei', 'Sam', 'Sonn'];
return (
  <div>
    <div style={{ color: 'black' }} className="container">
      <NavBar />
      <ImageScroll user={user}/>
      <div className="profile-section">
        <div className="profile-image">
          <img src={user?.prefixedProfileImage ? `${user?.prefixedProfileImage}` : '/docSahab.jpg'} alt="doctor Profile" />
        </div>
        <div className="profile-info">
          {/* <div className="profile-header">{`${user?.title} ${user?.firstname} ${user?.lastname}`}</div> */}
          <div className="profile-header">{`${user?.profileName}`}</div>
          <div className="profile-header1">{user?.professionList ? utils.getLanguageString(language,user?.professionList?.join(', '), user?.professionListDe?.join(', ')):''}</div>
          <div className="p-h-c">
            <div className="p-h-cont1">
              <MdPhoneInTalk className="p-h-i" />
              {user?.centers && user?.centers?.length > 0 ? (
                <span className="my-0 font-weight-normal">{user?.centers[0]?.phone}</span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="p-h-cont">
              <ImLocation2 className="p-h-i" />
              {user?.centers && user?.centers?.length ? (
                <span className="my-0 font-weight-normal text-left">{docLocation}</span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="p-h-cont1">
              <SlSpeech className="p-h-i" />
              {user?.centers && user?.centers?.length ? (
                <span className="my-0 font-weight-normal">
                  {user?.centers[0]?.mail}
                </span>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>
        <div className="p-h-c-r">
          <div className="p-h-c-r-h">{ST.label}{ user?.ratingGoogle == null ? '0': user?.ratingGoogle}</div>
          <div className="buttons">
          
          {isAuthenticated() && UserId == user?.userId ? (
              <Button
                style={{
                  backgroundColor: '#f35b2a',
                  margin: '10px',
                  fontWeight: 'bold',
                  boxShadow:'none'
                }}
                onClick={takeToEdit}
              >
                {ST.label1}
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: '#f35b2a',
                  margin: '10px',
                  fontWeight: 'bold',
                  boxShadow:'none'
                }}
                onClick={handleOpen1}
              >
                {ST.label2}
              </Button>
            )}
          </div>
        </div>
      </div>

      <hr />


        <div className='cont-top'>
          <div className='cont-top-c'>
            {ST.label3}
          </div>
          <div className='cont-top-i'>
            <a href= {`https://www.google.com/maps?q=${docLocationMap.lat},${docLocationMap.lng}`} target="_blank" rel="noopener noreferrer"> 
            <div className='cont-im'>
              <img src="/mp.svg" alt="" />
              <p className='cont-top-i-c'>{ST.label4}</p>
            </div>
            </a>
          </div>
        </div>

      <div className='doc-cont'>
        {user?.about == null? '' : utils.getLanguageString(user?.about ,user?.aboutDe,language) }
      </div>

    
      <div className="custom-container">
        <div className="custom-column">
          <div className="custom-company-section">
            <div className="custom-header" >
              <FaUserShield size={30} />
              <p>{ST.label5}</p>
            </div>
            <div className="custom-subheading1">
            { user?.insuranceCompanyList?.length>0? user?.insuranceCompanyList?.map((insurance, index) => (
              <p style={{marginRight:'5px'}} key={index}>
                { index==user?.insuranceCompanyList?.length-1 ? `${insurance}`:`${insurance},`}
              </p>
            )):<p></p>}
            </div>
        </div>

        <div className="custom-company-section">
          <div className="custom-header" >
            <LuClock3 size={30} />
            <p>{ST.label6}</p>
          </div>
          <div
            className="custom-subheading">
            {
             language === 'English'?
             dayNames.map((dayName, index) => {
              // Find matching slot for current day (if exists)
              const daySlot = Array.isArray(dayWiseSlots) 
                ? dayWiseSlots.find(slot => slot.dow === index + 1) 
                : null;
                
              // Get time slots if they exist, otherwise empty string
              const beforeLunch = daySlot?.beforeLunchSt 
                ? `${convertToTime(daySlot.beforeLunchSt)} - ${convertToTime(daySlot.beforeLunchEn)}` 
                : '';
                
              const afterLunch = daySlot?.afterLunchSt 
                ? `${convertToTime(daySlot.afterLunchSt)} - ${convertToTime(daySlot.afterLunchEn)}` 
                : '';
            
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 0.5, textAlign: 'start' }}>{dayName}</div>
                  <div style={{ flex: 1, textAlign: 'center' }}>{beforeLunch}</div>
                  <div style={{ flex: 1, textAlign: 'center' }}>{afterLunch}</div>
                </div>
              );
            }):
            dayNamesDe.map((dayName, index) => {
              // Find matching slot for current day (if exists)
              const daySlot = Array.isArray(dayWiseSlots) 
                ? dayWiseSlots.find(slot => slot.dow === index + 1) 
                : null;
                
              // Get time slots if they exist, otherwise empty string
              const beforeLunch = daySlot?.beforeLunchSt 
                ? `${convertToTime(daySlot.beforeLunchSt)} - ${convertToTime(daySlot.beforeLunchEn)}` 
                : '';
                
              const afterLunch = daySlot?.afterLunchSt 
                ? `${convertToTime(daySlot.afterLunchSt)} - ${convertToTime(daySlot.afterLunchEn)}` 
                : '';
            
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 0.5, textAlign: 'start' }}>{dayName}</div>
                  <div style={{ flex: 1, textAlign: 'center' }}>{beforeLunch}</div>
                  <div style={{ flex: 1, textAlign: 'center' }}>{afterLunch}</div>
                </div>
              );
            })
            }
          </div>
       
        </div>
      </div>

      <div className="custom-column">
        <div className="custom-company-section" >
          <div className="custom-header" >
            <FaUserGraduate size={30} />
            <p>{ST.label7}</p>
          </div>
          <div
            className="custom-subheading"
          >
            <p>{user?.education}</p>
          </div>
        </div>
        <div className="custom-company-section" >
          <div className="custom-header" >
            <LuFileSpreadsheet size={30} />
            <p>{ST.label8}</p>
          </div>
          <div className="custom-subheading">
            <p>{user?.graduationUniversity}</p>
          </div>
        </div>
      </div>


        <div className="custom-column">
          <div className="custom-media-section">
            <p>{ST.label9}</p>
            <div className="custom-image-gallery">
              <ImageModal user={user} />
            </div>
          </div>
        </div>

        {/* <div className="custom-network-section">
          <div className="custom-network-header">{ST.label10}</div>
        </div> */}


        <div className='custom-review-section'>
  <div className='custom-review-header'>
    <p>{ST.label11}</p>
    {isAuthenticated()?
    found?<></>:<Button onClick={handleOpen} style={{backgroundColor: '#f35b2a',color:'white'}} className='review-btn'>
    <LiaPenSolid size={20} />
    {ST.label12}
  </Button>
    :
    <div className="text-center">
  <Button
    className="review-btn"
    style={{ 
      backgroundColor: 'grey', 
      color: 'black', 
      width: 'auto',           
      maxWidth: '200px',       
      padding: '10px 20px',    
      display: 'inline-flex',
      alignItems: 'start',
      justifyContent: 'center',
      overflow: 'hidden',      
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'     
    }}
    onClick={takeToLogin}
  >
    <LiaPenSolid size={20} />
    <div
      style={{ 
        width: 'auto',
        maxWidth: '200px',
        marginLeft: '8px', 
        fontSize: '12px',      
        overflow: 'hidden',   
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap'   
      }}
    >
      {ST.label12}
    </div>
  </Button>
  <div 
    className="hidden-text" 
    style={{ 
      width: 'auto',
        maxWidth: '200px',
      whiteSpace: 'nowrap', 
      textAlign: 'center', 
      marginTop: '8px', 
      fontSize: '10px',  
      overflow: 'hidden',   
        textOverflow: 'ellipsis', 
            
    }}
  >
    {ST.label13}
  </div>
</div>



    }
    
    
  </div>
  {/* <div className='review-summary'>
    <div className='rating-bar'>
      <div className='rating-bar-star'>
        <span>5</span>
        <div className='bar'>
          <div className='fill' style={{ width: '80%' }}></div>
        </div>
      </div>
      <div className='rating-bar-star'>
        <span>4</span>
        <div className='bar'>
          <div className='fill' style={{ width: '60%' }}></div>
        </div>
      </div>
      <div className='rating-bar-star'>
        <span>3</span>
        <div className='bar'>
          <div className='fill' style={{ width: '40%' }}></div>
        </div>
      </div>
      <div className='rating-bar-star'>
        <span>2</span>
        <div className='bar'>
          <div className='fill' style={{ width: '20%' }}></div>
        </div>
      </div>
      <div className='rating-bar-star'>
        <span>1</span>
        <div className='bar'>
          <div className='fill' style={{ width: '10%' }}></div>
        </div>
      </div>
    </div>
    
    <div className='overall-rating'>
      <h1>{formattedRating}</h1>
      <div className='star-icons'>
        <span>★★★★☆</span> 
      </div>
      <p>{totalReviews} {ST.label14}</p>
    </div>
  </div> */}

<div className="review-summary">
      <div className="rating-bar">
        {[5, 4, 3, 2, 1].map((star) => {
          const count = ratingCounts[`rating${star}Count`];
         
          return (
            <div key={star} className="rating-bar-star">
              <span>{star}</span>
              <div className="bar">
                {console.log(`bhau`,rating1Count)}
                <div className="fill" style={{ width: `${getBarWidth(count)}%` }}></div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="overall-rating">
        <h1>{(isNaN(parseFloat(rating)) ? 0 : parseFloat(rating)).toFixed(1)}</h1>
        <div className="star-icons">
          <span>{'★'.repeat(Math.floor(rating)) + '☆'.repeat(5 - Math.floor(rating))}</span>
        </div>
        <p>{ratingCount} {ratingCount === 1 ? 'review' : 'reviews'}</p>
      </div>
    </div>
 
</div>
  
  <div className='Reviews'>

      {reviews.map((review) => (
        <ReviewCard user={user} review={review} UserId={UserId} found={found} pageNumber={pageNumber} fetchReviews={fetchReviews} />
      ))}

      {!isLastPage && reviews.length < totalReviews && (
        <button className='see-more' onClick={loadMoreReviews}>{ST.label15}</button>
      )}

  </div>


      </div>


      <Modal show={show} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton style={headerStyle}>
          <Modal.Title >
            {ST.label16}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={reviewFormStyle}>
            <Form onSubmit={handleSubmit1} action="" style={{padding:'0px',margin:'1rem'}}>
          <div class="stars">
            <Form action="" style={{padding:'0px'}}>

            <input class="star star-5" id="star-5" type="radio" value='5' name="rating" onChange={handleRatingChange1}/>
            <label class="star star-5" for="star-5"></label>
            <input class="star star-4" id="star-4" type="radio" value='4' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-4" for="star-4"></label>
            <input class="star star-3" id="star-3" type="radio" value='3' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-3" for="star-3"></label>
            <input class="star star-2" id="star-2" type="radio" value='2' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-2" for="star-2"></label>
            <input class="star star-1" id="star-1" type="radio" value='1' name="rating"  onChange={handleRatingChange1}/>
            <label class="star star-1" for="star-1"></label>
            </Form>
          </div>
            <textarea
              placeholder={ST.label18}
              style={textareaStyle1}
              name="ratingTitle"
              value={ReviewFormData.ratingTitle}
              onChange={handleInputChange1}
            />
            <textarea
              placeholder={ST.label19}
              style={textareaStyle}
              name="ratingBody"
              value={ReviewFormData.ratingBody}
              onChange={handleInputChange1}
            />
            <Button variant="primary" type='submit' onClick={handleClose} style={postButtonStyle}>
            {ST.label17}
            </Button>
            </Form>
          </div>
        </Modal.Body>
        
      </Modal>







    <Modal show={show1} onHide={handleClose1} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{MT.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className=" bg-dark text-white">
      <div style={{ backgroundColor: '#f35b2a' }} className='p-4'>
        <h5 style={{textAlign: 'justify',textJustify: 'inter-word'}} className=" mb-4">
        {MT.label1}
        </h5>
        <p style={{textAlign: 'justify',textJustify: 'inter-word'}} className="text-white h5 font-weight-bold mb-4">
        {MT.label2}
        </p>
      </div>
      <div className='p-4'>
        <Form ref={form} onSubmit={handlePreSubmit}>
          <Row className="mb-3 ">
            <Col>
              <Form.Group controlId="formName">
                <Form.Label style={{color:'white'}}>{MT.label3}</Form.Label>
                <Form.Control type="text" name="fullName" placeholder={MT.label24} value={formData.fullName} onChange={handleInputChange} />
                {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
              </Form.Group>
            </Col>
          </Row>

          

          <Row className="mb-3">
            <Col>
              <Form.Label style={{color:'white'}}>{MT.label4}</Form.Label>
              <div className="d-flex justify-content-around">
                <Form.Check type="radio" label={MT.label14} name="preferredTimeslot" value="Morning" id="morning" onChange={handleInputChange} checked={formData.preferredTimeslot === 'Morning'} />
                <Form.Check type="radio" label={MT.label15} name="preferredTimeslot" value="Afternoon" id="afternoon" onChange={handleInputChange} checked={formData.preferredTimeslot === 'Afternoon'} />
                <Form.Check type="radio" label={MT.label16} name="preferredTimeslot" value="Evening" id="evening" onChange={handleInputChange} checked={formData.preferredTimeslot === 'Evening'} />
              </div>
              {errors.preferredTimeslot && <div className="text-danger">{errors.preferredTimeslot}</div>}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formSpecificTimes">
                <Form.Label style={{color:'white'}}>{MT.label5}</Form.Label>
                <Form.Control type="text" name="preferredAvoidDays" placeholder={MT.label25} value={formData.preferredAvoidDays} onChange={handleInputChange} />
                {errors.preferredAvoidDays && <div className="text-danger">{errors.preferredAvoidDays}</div>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Label style={{color:'white'}}>{MT.label6}</Form.Label>
              <div className="d-flex justify-content-around">
                <Form.Check type="radio" label={MT.label17} name="isAptUrgent" value="Yes" id="urgentYes" onChange={handleInputChange} checked={formData.isAptUrgent === 'Yes'} />
                <Form.Check type="radio" label={MT.label18} name="isAptUrgent" value="No" id="urgentNo" onChange={handleInputChange} checked={formData.isAptUrgent === 'No'} />
              </div>
              {errors.isAptUrgent && <div className="text-danger">{errors.isAptUrgent}</div>}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Label style={{color:'white'}}>{MT.label7}</Form.Label>
              <div className="d-flex justify-content-around">
                <Form.Check type="radio" label={MT.label19} name="insuranceType" value="Public" id="public" onChange={handleInputChange} checked={formData.insuranceType === 'Public'} />
                <Form.Check type="radio" label={MT.label20} name="insuranceType" value="Private" id="private" onChange={handleInputChange} checked={formData.insuranceType === 'Private'} />
              </div>
              {errors.insuranceType && <div className="text-danger">{errors.insuranceType}</div>}
              <Form.Group controlId="formInsuranceNumber" className="mt-3">
                <Form.Label style={{color:'white'}}>{MT.label8}</Form.Label>
                <Form.Control type="text" name="insuranceNumber" placeholder={MT.label21} value={formData.insuranceNumber} onChange={handleInputChange} />
                {errors.insuranceNumber && <div className="text-danger">{errors.insuranceNumber}</div>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formNotes">
                <Form.Label style={{color:'white'}}>{MT.label9}</Form.Label>
                <Form.Control as="textarea" name="specialMention" rows={3} value={formData.specialMention} onChange={handleInputChange} />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formPhone">
                <Form.Label style={{color:'white'}}>{MT.label10}</Form.Label>
                <Form.Control type="text" name="phone" placeholder={MT.label22} value={formData.phone} onChange={handleInputChange} />
                {errors.phone && <div className="text-danger">{errors.phone}</div>}
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label style={{color:'white'}}>{MT.label11}</Form.Label>
                <Form.Control type="email" name="email" placeholder={MT.label23}value={formData.email} onChange={handleInputChange} />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3 align-items-center">
            <Col xs="auto" className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                id="termsConditions"
                name="terms_conditions"
                checked={formData.terms_conditions}
                onChange={handleInputChange}
                className="me-2"
              />
              <Form.Label style={{ color: 'white' }} className="mb-0">
                {MT.label12}
              </Form.Label>
            </Col>
            <Col xs="auto">
              {errors.terms_conditions && (
                <div className="text-danger">{errors.terms_conditions}</div>
              )}
            </Col>
          </Row>

          <Button style={{ backgroundColor: '#f35b2a' }} type="submit" className="w-100">
            {MT.label13}
          </Button>
        </Form>
      </div>
    </div>
      </Modal.Body>
      <Modal.Footer>
       
      </Modal.Footer>
    </Modal>
   
    </div>

      <Footer />
    </div>
  )
}

export default DocProfileView



const headerStyle = {
  color: 'black',
};

const reviewFormStyle = {
  maxHeight: '700px',
  backgroundColor: '#333',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const starRatingStyle = {
  fontSize: '24px',
  color: 'gold',
  marginBottom: '20px',
};

const textareaStyle1 = {
  width: '100%',
  height: '100px',
  padding: '10px',
  marginBottom: '20px',
  border: '1px solid #444',
  backgroundColor: '#333',
  color: 'white',
  borderRadius: '5px',
  resize: 'none',
};

const textareaStyle = {
  width: '100%',
  height: '200px',
  padding: '10px',
  marginBottom: '20px',
  border: '1px solid #444',
  backgroundColor: '#333',
  color: 'white',
  borderRadius: '5px',
  resize: 'none',
};

const addMediaButtonStyle = {
  backgroundColor: '#444',
  border: 'none',
  color: '#f35b2a',
  padding: '10px',
  cursor: 'pointer',
  borderRadius: '5px',
  marginBottom: '20px',
};

const cancelButtonStyle = {
  backgroundColor: '#fab8a4',
};

const postButtonStyle = {
  justifyContent: 'center',
  alignSelf: 'center',
  margin:'20px 0px',
  backgroundColor:'#f35b2a',
};
