import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSignOut, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { useLanguage } from "./LanguageContext.js";
import HomePageView from "./HomePageView.jsx";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";

const Homepage = () => {
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.HOMEPAGE;
  console.log(`Sarthak`,ST);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 960);
  const [detectedLocation, setDetectedLocation] = useState('Location');

  const handleNavigation = useCallback((path) => {
    console.log(path);
    navigate(path);
  }, [navigate]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth <= 960);
    }, 100);
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const karwaisko = useCallback(() => { 
    signOut();
    handleNavigation('/');
  }, [signOut, handleNavigation]);

  


  return (
    <HomePageView 
      authUser={authUser}
      handleNavigation={handleNavigation}
      isAuthenticated={isAuthenticated}
      isMobile={isMobile}
      language={language}
      langStrings={langStrings}
      ST={ST}
      toggleLanguage={toggleLanguage}
      karwaisko={karwaisko}
      isTablet={isTablet}
      detectedLocation={detectedLocation}
    />
  );
};

export default Homepage;
