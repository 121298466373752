import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useMap, InfoWindow, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import _ from 'lodash';
import './css/PoiMarker.css';

const PoiMarker = (props) => {
  const map = useMap();
  const [markers, setMarkers] = useState({});
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState("");

  const profileImagePrefix =
    process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;

  // Store map center and zoom (useRef to persist values across renders)
  const mapStateRef = useRef({ center: null, zoom: null });
  const clusterer = useRef(null);

  // Initialize MarkerClusterer
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // Update markers in the clusterer
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker, key) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;
  };

  const handleMarkerClick = useCallback((poi) => {
    if (!map || !poi) return;

    // Save the map's current center and zoom before panning
    mapStateRef.current = {
      center: map.getCenter(),
      zoom: map.getZoom(),
    };

    map.panTo(poi.location);
    setSelectedMarker(poi);
    setInfoWindowShown(true);
  }, [map]);
  function getDocLocAddressStr(loc){
	let str='';
	let ar = [];
	if(loc.address)
		ar.push(loc.address);
	if(loc.street)
		ar.push(loc.street);
	if(loc.area)
		ar.push(loc.area);
	if(loc.cityStr)
		ar.push(loc.cityStr)


	str= ar.join(', ');
	return str;
  }
  const closeInfoWindow = () => {
    setSelectedMarker("");
    setInfoWindowShown(false);

    // Only restore the map's previous center and zoom if they are not null
    if (map && mapStateRef.current.center && mapStateRef.current.zoom) {
      map.panTo(mapStateRef.current.center);
      map.setZoom(mapStateRef.current.zoom);
    }
  };

  const filteredLocations = filteredlocation(props.srpData.doctorList);

  useEffect(() => {
    if (filteredLocations.length > 0 && map) {
      const selected = filteredLocations.find((loc) => loc.data.isSelected);
      if (selected) {
        map.setCenter(selected.location);
        map.setZoom(12);
      } else {
        map.setCenter(filteredLocations[0].location);
        map.setZoom(12);
      }
    }
  }, [filteredLocations, map]);

  return (
    <div className="poi">
      {filteredLocations.map((poi) => (
        <AdvancedMarker
          key={poi.key}
          position={poi.location}
          ref={(marker) => setMarkerRef(marker, poi.key)}
          clickable={true}
          onClick={() => handleMarkerClick(poi)}
        >
          {poi.data.isSelected ? (
            <Pin background="#FBBC04" glyphColor="#000" borderColor="#000" />
          ) : (
            <Pin background="#ABCDEF" glyphColor="#222" borderColor="#333" />
          )}
        </AdvancedMarker>
      ))}

      {infoWindowShown && selectedMarker && (
        <InfoWindow position={selectedMarker.location}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <div
              id="image-profile-id"
              className="image-profile-div"
              style={{
                width: '100%',
                maxWidth: '300px',
                marginBottom: '10px',
              }}
            >
              <picture style={{ width: '100%' }}>
                <img
                  src={profileImagePrefix + selectedMarker.data.profileImage}
                  className="doc-listing-profile-logo"
                  alt={selectedMarker.data.displayName}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                />
              </picture>
            </div>
            <p style={{ color: 'black', fontWeight: 'bold', margin: '5px 0' }}>
              {selectedMarker.data.displayName}
            </p>
            <p style={{ color: 'black', margin: '5px 0' }}>
              {getDocLocAddressStr(selectedMarker.data.centers[0])}
            </p>
            <button
              onClick={closeInfoWindow}
              style={{
                padding: '8px 16px',
                border: 'none',
                borderRadius: '4px',
                background: '#007BFF',
                color: '#FFF',
                cursor: 'pointer',
                marginTop: '10px',
              }}
            >
              Close
            </button>
          </div>
        </InfoWindow>
      )}
    </div>
  );
};

function filteredlocation(doctorList) {
  let resultAr = [];
  doctorList.forEach((doctor) => {
    let key = _.get(doctor, 'id', 0);
    let lat = _.get(doctor, 'centers[0].lat', 0);
    let lng = _.get(doctor, 'centers[0].lng', 0);

    if (key && lat && lng) {
      resultAr.push(getObj(key, lat, lng, doctor));
    }
  });
  return resultAr;
}

function getObj(key, lat, lng, doctor) {
  return {
    key,
    location: { lat: Number(lat), lng: Number(lng) },
    data: doctor,
  };
}

export default PoiMarker;
