import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios'; 
import { useLanguage } from '../../../homepage/components/LanguageContext';
import { useAuthUser } from 'react-auth-kit';
import { toast } from 'sonner';

const EducationInfo = () => {
  const { user,setUser } = useOutletContext();
  const [graduation, setGraduation] = useState(user?.education || "" );
  const [diplomaCertificate, setDiplomaCertificate] = useState(user?.graduationUniversity || "" ); 
  const authUser = useAuthUser();
  const {language,langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.EDUCATIONINFO;
  useEffect(()=>{
    setGraduation(user?.education);
    setDiplomaCertificate(user?.graduationUniversity)
  },[user])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateData = {
      education: graduation,
      graduationUniversity: diplomaCertificate
    };

    try {
      toast.info(ST.UpdateInProccess, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken ||  ''
      const response = await axios.put(`${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`, updateData, {
        headers: {
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': token
        },
      });
      setTimeout(() => {
        if (response.status === 200) {
          toast.success(ST.DetailsUpdated, {
            position: 'top-center',
            duration: 3000,
            style: {
              fontSize: '15px', 
              padding: '10px 20px', 
              minWidth: '200px',
            },
          });
          setUser(response.data);
        }
      },300)
    } catch (err) {
      console.error('API error:', err);
      toast.warning(ST.FailedToUpdate, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
    }
  };

  
  

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formGraduation">
          <Form.Label>{ST.label}</Form.Label>
          <Form.Control
            as="textarea"
            rows={4} 
            value={graduation}
            placeholder="Institute Name / Degree Name "
            onChange={(e) => setGraduation(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDiplomaCertificate">
          <Form.Label>{ST.label1}</Form.Label>
          <Form.Control
            as="textarea"
            rows={4} 
            value={diplomaCertificate}
            placeholder="Your Certifications"
            onChange={(e) => setDiplomaCertificate(e.target.value)}
          />
        </Form.Group>
        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label2}
        </Button>
      </Form>
    </div>
  );
};

export default EducationInfo;
