import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';  
import debounce from 'lodash/debounce';  
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './SearchFil.css'
import { useLanguage } from '../../homepage/components/LanguageContext.js';
import { IoIosCloseCircleOutline } from "react-icons/io";

const SearchFil = () => {
  const { langStrings , updateLanguageFilter,updateVerifiedFilter,updateAcceptingFilter,updateInsuranceFilter} = useLanguage();
  const ST = langStrings.SEARCHFIL;
  const defaultSpecialityOptions = [
    { value: 'Paediatrician', label: 'Paediatrician' },
    { value: 'Nephrologist', label: 'Nephrologist' },
    { value: 'Cardiologist', label: 'Cardiologist' },
    { value: 'Orthopaedist', label: 'Orthopaedist' },
    { value: 'Psychiatrist', label: 'Psychiatrist' },
    { value: 'Neurologist', label: 'Neurologist' },
    { value: 'Gynaecologist', label: 'Gynaecologist' },
    { value: 'Surgeon', label: 'Surgeon' },
    { value: 'General Practitioner', label: 'General Practitioner' },
  ];

  const defaultLocationOptions = [
    { value: 'Vienna', label: 'Vienna' },
    { value: 'Salzburg', label: 'Salzburg' },
    { value: 'Innsbruck', label: 'Innsbruck' },
    { value: 'Graz', label: 'Graz' },
    { value: 'Linz', label: 'Linz' },
    { value: 'Klagenfurt', label: 'Klagenfurt' },
    { value: 'St_pölten', label: 'St. Pölten' },
    { value: 'Bregenz', label: 'Bregenz' },
    { value: 'Villach', label: 'Villach' },
    { value: 'Wels', label: 'Wels' },
  ];

  const [specialtyOptions, setSpecialtyOptions] = useState(defaultSpecialityOptions);
  const [locationOptions, setLocationOptions] = useState(defaultLocationOptions);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const navigate = useNavigate();
  const search = useLocation().search;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
const handleMouseEnter = () => {
  setIsHovered(true);
};

const handleMouseLeave = () => {
  setIsHovered(false);
};


const handleMouseEnter1 = () => {
  setIsHovered1(true);
};

const handleMouseLeave1 = () => {
  setIsHovered1(false);
};


const handleMouseEnter2 = () => {
  setIsHovered2(true);
};

const handleMouseLeave2 = () => {
  setIsHovered2(false);
};

const handleMouseEnter3 = () => {
  setIsHovered3(true);
};

const handleMouseLeave3 = () => {
  setIsHovered3(false);
};


  const updateURLWithLanguage = (language) => {
    if (language) {
      searchParams.set('language', language);
    } else {
      searchParams.delete('language'); 
    }
    navigate({
      search: searchParams.toString()
    });
  };

  const updateURLWithAccepting = (Accepting) => {
    if (Accepting) {
      searchParams.set('acceptingNewPatient', Accepting);
    } else {
      searchParams.delete('acceptingNewPatient'); 
    }
    navigate({
      search: searchParams.toString()
    });
  };

  const updateURLWithInsurance = (insuranceGroup) => {
    if (insuranceGroup) {
      searchParams.set('insuranceGroup', insuranceGroup);
    } else {
      searchParams.delete('insuranceGroup');  
    }
    navigate({
      search: searchParams.toString()
    });
  };


  const updateURLWithVerified = (isVerified) => {
    if (isVerified) {
      searchParams.set('isVerified', isVerified);
    } else {
      searchParams.delete('isVerified'); 
    }
    navigate({
      search: searchParams.toString()
    });
  };
  
  let SUGGEST_URL = process.env.REACT_APP_JAVA_BASE_URL + '/suggest';
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedAccepting, setSelectedAccepting] = useState('');
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [selectedVerified, setSelectedVerified] = useState('');

  
  const handleLanguageSelect = (event,language,og) => {
    event.preventDefault();
    setSelectedLanguage(language);
    updateLanguageFilter(og);
    updateURLWithLanguage(og); 
  };

  const clearLanguageSelection = () => {
    setSelectedLanguage('');
    updateLanguageFilter(''); 
    updateURLWithLanguage('');
  };

  const handleInsuranceSelect = (event,flag,og) => {
    event.preventDefault();
    setSelectedInsurance(flag);
    updateURLWithInsurance(og); 
    updateInsuranceFilter(og); 
  };
  const clearInsuranceSelection = () => {
    setSelectedInsurance('');
    updateURLWithInsurance(''); 
    updateInsuranceFilter('');
  };
  const handleAcceptingSelect = (event,flag,og) => {
    event.preventDefault();
    setSelectedAccepting(flag);
    updateURLWithAccepting(og); 
    updateAcceptingFilter(og); 
  };

  const clearAcceptingSelection = () => {
    setSelectedAccepting('');
    updateURLWithAccepting(''); 
    updateAcceptingFilter(''); 
  };
  const handleVerifiedSelect = (event,flag,og) => {
    event.preventDefault();
    setSelectedVerified(flag);
    updateURLWithVerified(og); 
    updateVerifiedFilter(og); 
  };

  const clearVerifiedSelection = () => {
    setSelectedVerified('');
    updateURLWithVerified(''); 
    updateVerifiedFilter(''); 
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const locationParam = params.get('location');
    const specialityParam = params.get('speciality');

    if (locationParam) {
      const selectedLocationOption = defaultLocationOptions.find(
        option => option.value.toLowerCase() === locationParam.toLowerCase()
      );
      setSelectedLocation(selectedLocationOption || null);
    }

    if (specialityParam) {
      const selectedSpecialityOption = defaultSpecialityOptions.find(
        option => option.value.toLowerCase() === specialityParam.toLowerCase()
      );
      setSelectedSpeciality(selectedSpecialityOption || null);
    }
  }, [search]);

  const fetchSpecialtyOptions = debounce((inputValue) => {
    if (inputValue) {

      
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=searchkey`)
        .then(response => {
          const options = response.data.data.map(item => ({
            value: item.value,  
            label: item.title, 
          }));
          setSpecialtyOptions(options);
        })
        .catch(error => {
          console.error('Error fetching specialty options:', error);
        });
    }
  }, 300);  

  const fetchLocationOptions = debounce((inputValue) => {
    if (inputValue) {
      axios.get(`${SUGGEST_URL}?text=${inputValue}&type=locationkey`)
        .then(response => {
          const options = response.data.data.map(item => ({
            value: item.value,
            label: item.title, 
          }));
          setLocationOptions(options);
        })
        .catch(error => {
          console.error('Error fetching location options:', error);
        });
    }
  }, 300);


  const DropdownIndicator = () => null;

  return (
    <div className="container ">
      <div className="row" >
    <div className="col-12 col-md-6 col-lg-3 mb-3" style={{padding:'8px'}}>
      <div className="bg-custom rounded w-100 position-relative" >
        <button
          className="dropdown-toggle w-100 d-flex justify-content-between align-items-center no-caret  p-2 custom-drop"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            border: 'none',
            borderRadius: '5px',
            borderWidth: 'none',
            whiteSpace: 'normal',   // Allows text to wrap
            wordWrap: 'break-word', // Ensures long words will break
            minHeight: '70px',      // Set a minimum height for consistency
            display: 'flex',        // Flex to ensure consistent height
            alignItems: 'stretch',  
            backgroundColor: selectedLanguage ? '#ff9be7' : isHovered ? '#ff9be7' : '#efefef', 
            color: selectedLanguage ? 'white' : isHovered ? 'white' : 'black',
            cursor: 'pointer',
            padding:'0px'
          }}
        >
          {selectedLanguage?
          <div className="mx-auto fw-bold" >
            {selectedLanguage}<IoIosCloseCircleOutline size={20} style={{color:'white',marginLeft:'10px'}} onClick={clearLanguageSelection} />
            </div>: <span className="mx-auto fw-bold">{ST.FILTERS.label}</span>}
         
        </button>
        <ul className="dropdown-menu  custom-dropdown ">
          <li><a href="#" onClick={(e)=>handleLanguageSelect(e,ST.FILTERSINFO.label4,"English")} className="custom-dropdown-item">{ST.FILTERSINFO.label4}</a></li>
          <li><a href="#" onClick={(e)=>handleLanguageSelect(e,ST.FILTERSINFO.label5,"German")} className="custom-dropdown-item">{ST.FILTERSINFO.label5}</a></li>
          <li><a href="#" onClick={(e)=>handleLanguageSelect(e,ST.FILTERSINFO.label6,"French")} className="custom-dropdown-item">{ST.FILTERSINFO.label6}</a></li>
          <li><a href="#" onClick={(e)=>handleLanguageSelect(e,ST.FILTERSINFO.label7,"Italian")} className="custom-dropdown-item">{ST.FILTERSINFO.label7}</a></li>
          <li><a href="#" onClick={(e)=>handleLanguageSelect(e,ST.FILTERSINFO.label8,"Croatian")} className="custom-dropdown-item">{ST.FILTERSINFO.label8}</a></li>
        </ul>
      </div>
    </div>

    <div className="col-12 col-md-6 col-lg-3 mb-3" style={{padding:'8px'}}>
      <div className="bg-custom rounded w-100 position-relative">
        <button
          className="dropdown-toggle w-100 d-flex justify-content-between align-items-center no-caret rounded-lg p-3 custom-drop"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onMouseEnter={handleMouseEnter1}
          onMouseLeave={handleMouseLeave1}
          style={{
            
            border: 'none',
            borderRadius: '10px',
            borderWidth: 'none',
            whiteSpace: 'normal',   // Allows text to wrap
            wordWrap: 'break-word', // Ensures long words will break
            minHeight: '70px',      // Set a minimum height for consistency
            display: 'flex',        // Flex to ensure consistent height
            alignItems: 'stretch',  // Make all buttons stretch equally
            backgroundColor: selectedAccepting ? '#ff9be7' : isHovered1 ? '#ff9be7' : '#efefef', 
            color: selectedAccepting ? 'white' : isHovered1 ? 'white' : 'black',
            cursor: 'pointer',
            padding:'0px'
          }}
        >
          {selectedAccepting?
          <div className="mx-auto fw-bold text-center anp-btn" style={{ textAlign: 'center' }} >
            {selectedAccepting}<IoIosCloseCircleOutline size={20} style={{color:'white',marginLeft:'10px'}} onClick={clearAcceptingSelection} />
            </div>: <span className="mx-auto fw-bold text-center anp-btn" style={{ textAlign: 'center' }}>{ST.FILTERS.label1}</span>}
          
        </button>
        <ul className="dropdown-menu custom-dropdown">
          <li><a href="#" onClick={(e)=>handleAcceptingSelect(e,ST.FILTERSINFO.label,"Yes")} className="custom-dropdown-item">{ST.FILTERSINFO.label}</a></li>
          <li><a href="#" onClick={(e)=>handleAcceptingSelect(e,ST.FILTERSINFO.label1,"No")} className="custom-dropdown-item">{ST.FILTERSINFO.label1}</a></li>
        </ul>
      </div>
    </div>

    <div className="col-12 col-md-6 col-lg-3 mb-3" style={{padding:'8px'}}>
      <div className="bg-custom rounded w-100 position-relative">
        <button
          className="dropdown-toggle w-100 d-flex justify-content-between align-items-center no-caret rounded-lg  custom-drop"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
          style={{
            
            border: 'none',
            borderRadius: '10px',
            borderWidth: 'none',
            whiteSpace: 'normal',   
            wordWrap: 'break-word', 
            minHeight: '70px',      
            display: 'flex',        
            alignItems: 'stretch',  
            backgroundColor: selectedInsurance ? '#ff9be7' : isHovered2 ? '#ff9be7' : '#efefef', 
            color: selectedInsurance ? 'white' : isHovered2 ? 'white' : 'black',
            cursor: 'pointer', 
            padding:'0px'
          }}
        >
          {selectedInsurance?
          <div className="mx-auto fw-bold" >
            {selectedInsurance}<IoIosCloseCircleOutline size={20} style={{color:'white',marginLeft:'10px'}} onClick={clearInsuranceSelection} />
            </div>: <span className="mx-auto fw-bold">{ST.FILTERS.label2}</span>}
          
        </button>
        <ul className="dropdown-menu custom-dropdown">
          <li><a href="#" onClick={(e)=>handleInsuranceSelect(e,ST.FILTERSINFO.label2,"Legally insured")} className="custom-dropdown-item">{ST.FILTERSINFO.label2}</a></li>
          <li><a href="#" onClick={(e)=>handleInsuranceSelect(e,ST.FILTERSINFO.label3,"Privately insured and self-paying")} className="custom-dropdown-item">{ST.FILTERSINFO.label3}</a></li>
        </ul>
      </div>
    </div>

    <div className="col-12 col-md-6 col-lg-3 mb-3" style={{padding:'8px'}}>
      <div className="bg-custom rounded w-100 position-relative">
        <button
          className="dropdown-toggle w-100 d-flex justify-content-between align-items-center no-caret rounded-lg custom-drop"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
          style={{
            
            border: 'none',
            borderRadius: '10px',
            borderWidth: 'none',
            whiteSpace: 'normal',   // Allows text to wrap
            wordWrap: 'break-word', // Ensures long words will break
            minHeight: '70px',      // Set a minimum height for consistency
            display: 'flex',        // Flex to ensure consistent height
            alignItems: 'stretch',  // Make all buttons stretch equally
            backgroundColor: selectedVerified ? '#ff9be7' : isHovered3 ? '#ff9be7' : '#efefef', 
            color: selectedVerified ? 'white' : isHovered3 ? 'white' : 'black',
            cursor: 'pointer',
            padding:'0px'
          }}
        >
          {selectedVerified?
          <div className="mx-auto fw-bold" >
            {selectedVerified}<IoIosCloseCircleOutline size={20} style={{color:'white',marginLeft:'10px'}} onClick={clearVerifiedSelection} />
            </div>: <span className="mx-auto fw-bold">{ST.FILTERS.label3}</span>}
         
        </button>
        <ul className="dropdown-menu custom-dropdown">
          <li><a href="#" onClick={(e)=>handleVerifiedSelect(e,ST.FILTERSINFO.label,"Yes")} className="custom-dropdown-item">{ST.FILTERSINFO.label}</a></li>
          <li><a href="#" onClick={(e)=>handleVerifiedSelect(e,ST.FILTERSINFO.label9,"No")} className="custom-dropdown-item">{ST.FILTERSINFO.label9}</a></li>
        </ul>
      </div>
    </div>

  </div>

</div>

  );
};

export default SearchFil;
