import React from 'react';
import { Spinner } from 'react-bootstrap'; 

const Loader = () => (
  <div style={{
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh', 
    backgroundColor: '#f4f4f4',
    flexDirection: 'column',
  }}>
    <Spinner 
      animation="border" 
      role="status" 
      style={{
        width: '80px', 
        height: '80px', 
        color: '#f35b2a', 
        borderWidth: '6px'
      }}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    <span style={{
      marginTop: '10px',
      color: '#f35b2a', 
    }}>
      Loading...
    </span>
  </div>
);

export default Loader;