import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useLanguage } from '../../../homepage/components/LanguageContext';
import { useAuthUser } from 'react-auth-kit';
import { toast } from 'sonner';
import { ChartNoAxesColumnDecreasing } from 'lucide-react';

const ContactInfo = () => {
  const { user,setUser} = useOutletContext();
  const center = user? user.centers[0] : {};

  const [address, setAddress] = useState(center?.address || '');
  const [street, setStreet] = useState(center?.street || '');
  const [area, setArea] = useState(center?.area || '');
  const [email, setEmail] = useState(center?.mail || '');
  const [pincode, setPincode] = useState(center?.pincode || '');
  const [phone, setPhone] = useState(center?.phone || '');
  const [isdCode, setisdCode] = useState(center?.isdCode || '');
  const authUser = useAuthUser();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [website,setWebsite] = useState(center?.website || '');
  const { language,langStrings} = useLanguage();
  const ST = langStrings.DOCEDIT.CONTACTINFO;

  useEffect(() => {
    setAddress(user?.centers[0]?.address);
    setStreet(user?.centers[0]?.street);
    setArea(user?.centers[0]?.area);
    setEmail(user?.centers[0]?.mail);
    setPincode(user?.centers[0]?.pincode);
    setPhone(user?.centers[0]?.phone);
    setisdCode(user?.centers[0]?.isdCode);
    setWebsite(user?.centers[0]?.website);
  }, [user, language]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
        const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL}/template`, {
          headers: {
            'X-LOCALE': `${LanguageLocale}`,
            'X-AUTHTOKEN': authUser().authToken
          },
        });
        const result = await response.json();
        setCountries(result.countryData || []);
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };
    fetchData();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    const country = countries.find((c) => c.id === parseInt(selectedOption.value));
    setStates(country?.states || []);
    setSelectedState(null);
    setCities([]);
    setSelectedCity(null);
  };
  
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    const state = states.find((s) => s.id === parseInt(selectedOption.value));
    setCities(state?.cities || []);
    setSelectedCity(null);
  };

  useEffect(() => {
    if (selectedCountry) {
      const country = countries.find((c) => c.id === parseInt(selectedCountry.value));
      setStates(country?.states || []);
      
      setCities([]);
    }
  }, [selectedCountry, countries]);

  useEffect(() => {
    if (selectedState) {
      const state = states.find((s) => s.id === parseInt(selectedState.value));
      setCities(state?.cities || []);
    
    }
  }, [selectedState, states]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      centers: [
        {
          id: center.id,
          doctorId: user.id,
          practiceSize:center.practiceSize,
          address,
          street,
          area,
          pincode,
          website,
          countryId: selectedCountry?.value || user.countryId,
          stateId: selectedState?.value || user.stateId,
          cityId: selectedCity?.value || user.cityId,
          mail:email,
          lat:center.lat,
          lng:center.lng,
          phone,
          isdCode,
          createdAt:center.createdAt,
          updatedAt: center.updatedAt,
          attributes: { 
            property1: "property1 value", 
            prop2: "property2 value" 
          },
          status: center.status
        }
      ]
    };


    try {
      toast.info(ST.UpdateInProccess, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
      const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-LOCALE': `${LanguageLocale}`,
          'X-AUTHTOKEN': authUser().authToken
        },
        body: JSON.stringify(updatedData)
      });
      const data = await response.json();
      console.log('here is data',data);
      setTimeout(()=>{
      if (response.ok) {
        toast.success(ST.DetailsUpdated, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        setUser(data);
      } else {
        toast.warning(ST.FailedToUpdate, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        console.error('Error updating doctor data');
      }
    },300)
    } catch (error) {
      toast.warning(ST.FailedToUpdate, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      console.error('Error:', error);
    }
  };
  useEffect(() => {
    if (center?.countryId) {
      const country = countries.find(c => c.id === center.countryId);
      if (country) {
        setSelectedCountry({ value: country.id, label: language == 'English' ?  country.name : country.nameDe });
        
        if (center.stateId) {
          const state = country.states.find(s => s.id === center.stateId);
         
          if (state) {
            setSelectedState({ value: state.id, label: language == 'English' ?  state.name : state.nameDe});
            
            if (center.cityId) {
              const city = state.cities.find(c => c.id === center.cityId);
              if (city) {
                setSelectedCity({ value: city.id, label: language == 'English' ?  city.name : city.nameDe });
              }
            }
          }
        }
      }
    }
  }, [center, countries]);

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formaddress">
          <Form.Label>{ST.label}</Form.Label>
          <Form.Control
            type="text"
            value={address}
            placeholder="Enter Your Address"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formstreet">
          <Form.Label>{ST.label1}</Form.Label>
          <Form.Control
            type="text"
            value={street}
            placeholder="Enter Your Street name"
            onChange={(e) => setStreet(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formarea">
          <Form.Label>{ST.label2}</Form.Label>
          <Form.Control
            type="text"
            value={area}
            placeholder="Enter Your Locality"
            onChange={(e) => setArea(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formtitle">
            <Form.Label>{ST.label3}</Form.Label>
            <Select 
              options={countries.map(country => ({ value: country.id, label: country.name }))}
              value={selectedCountry}
              onChange={handleCountryChange}
              placeholder={selectedCountry?.name || 'Select Country'}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                  border: '1px solid #ced4da', // Bootstrap's border
                  borderRadius: '0.375rem', // Bootstrap's border-radius
                  fontSize: '1rem', // Bootstrap's font-size
                  width: '100%', // Full-width
                  paddingRight: '0.75rem', // Bootstrap padding
                  boxShadow: 'none', // Remove shadow
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', // Matching Bootstrap font-size
                  color: '#495057', // Bootstrap input text color
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000, // Ensure dropdown shows above other content
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                  color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', // Light gray background for selected values
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', 
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', 
                  ':hover': {
                    backgroundColor: '#ced4da', 
                    color: 'black',
                  },
                }),
              }}
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formtitle">
            <Form.Label>{ST.label4}</Form.Label>
            <Select 
               options={states.map(state => ({ value: state.id, label: state.name }))}
               value={selectedState}
               onChange={handleStateChange}
               placeholder={selectedState ? selectedState.label : 'Select State'}
               isDisabled={!selectedCountry}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                  border: '1px solid #ced4da', // Bootstrap's border
                  borderRadius: '0.375rem', // Bootstrap's border-radius
                  fontSize: '1rem', // Bootstrap's font-size
                  width: '100%', // Full-width
                  paddingRight: '0.75rem', // Bootstrap padding
                  boxShadow: 'none', // Remove shadow
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', // Matching Bootstrap font-size
                  color: '#495057', // Bootstrap input text color
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000, // Ensure dropdown shows above other content
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                  color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', // Light gray background for selected values
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', // Bootstrap text color
                  ':hover': {
                    backgroundColor: '#ced4da', // Hover effect for removing tag
                    color: 'black',
                  },
                }),
              }}
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formtitle">
            <Form.Label>{ST.label5}</Form.Label>
            <Select 
              options={cities.map(city => ({ value: city.id, label: city.name }))}
              value={selectedCity}
              onChange={(selectedOption) => setSelectedCity(selectedOption)}
              placeholder={selectedCity?.name || 'Select City'}
              isDisabled={!selectedState}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 'calc(1.5em + 0.75rem + 2px)', // Bootstrap's form-control height
                  border: '1px solid #ced4da', // Bootstrap's border
                  borderRadius: '0.375rem', // Bootstrap's border-radius
                  fontSize: '1rem', // Bootstrap's font-size
                  width: '100%', // Full-width
                  paddingRight: '0.75rem', // Bootstrap padding
                  boxShadow: 'none', // Remove shadow
                  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out', // Bootstrap transition
                }),
                input: (base) => ({
                  ...base,
                  fontSize: '1rem', // Matching Bootstrap font-size
                  color: '#495057', // Bootstrap input text color
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 1000, // Ensure dropdown shows above other content
                  backgroundColor: 'white',
                  border: '1px solid #ced4da',
                  borderRadius: '0.375rem', // Match Bootstrap's dropdown menu styling
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)', // Light shadow for the dropdown
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected ? '#0d6efd' : 'white', // Bootstrap primary color for selected
                  color: state.isSelected ? 'white' : '#495057', // White text when selected, black otherwise
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e9ecef', // Light gray background for selected values
                  borderRadius: '0.375rem',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  fontSize: '0.875rem', // Match Bootstrap label sizing for multi-select
                  padding: '0.25rem 0.5rem',
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#495057', // Bootstrap text color
                  ':hover': {
                    backgroundColor: '#ced4da', // Hover effect for removing tag
                    color: 'black',
                  },
                }),
              }}
            />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formpincode">
          <Form.Label>{ST.label6}</Form.Label>
          <Form.Control
            type="text"
            value={pincode}
            placeholder="Enter Your PIN Code"
            onChange={(e) => setPincode(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formpemail">
          <Form.Label>{ST.label7}</Form.Label>
          <Form.Control
            type="email"
            value={email}
            placeholder="Enter Your Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formwebsite">
          <Form.Label>{ST.website}</Form.Label>
          <Form.Control
            type="text"
            value={website}
            placeholder="Enter Your Website URL"
            onChange={(e) => setWebsite(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formisdCode">
          <Form.Label>{ST.label8}</Form.Label>
          <Form.Control
            type="text"
            value={isdCode}
            placeholder="Enter Your Isd Code"
            onChange={(e) => setisdCode(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formphone">
          <Form.Label>{ST.label9}</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            placeholder="Enter Your Phone Number"
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>

        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label10}
        </Button>
      </Form>
    </div>
  );
};

export default ContactInfo;