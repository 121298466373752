import React, { useEffect, useState, useCallback } from 'react';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaCamera } from 'react-icons/fa'; 
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../../../utils';
import './ProfileHeader.css';
import { useLanguage } from '../../../homepage/components/LanguageContext';
import { toast } from 'sonner';
import { useAuthUser } from 'react-auth-kit';

const ProfileHeader = ({ user }) => {
  const [profileImage, setProfileImage] = useState(user?.prefixedProfileImage ? `${user?.prefixedProfileImage}` : '/docSahab.jpg');
  const navigate = useNavigate();
  const { language,langStrings } = useLanguage();
  const DT = langStrings.DOCEDIT.PROFILEHEADER;
  const [imageToCrop, setImageToCrop] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const authUser = useAuthUser();
  const handleImageChange = async (event) => {
    const file = event.target.files[0]; 
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageToCrop(imageUrl);
      setIsCropping(true);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropConfirm = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageToCrop,
        croppedAreaPixels
      );

    
      const croppedImageFile = new File([croppedImage], 'cropped-profile.jpg', {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('File', croppedImageFile);
      
      const languageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      const response = await fetch(
        process.env.REACT_APP_JAVA_BASE_URL + `/doctor/uploadFile?doctorId=${user.id}`, 
        {
          method: 'POST',
          body: formData,
          headers: {
            'X-LOCALE': `${languageLocale}`,
            'X-AUTHTOKEN': token
          },
        }
      );

      if (response.ok) {
        setProfileImage(URL.createObjectURL(croppedImage));
        toast.success(DT.label, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
      } else {
        toast.warning(DT.label1, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        setProfileImage(user.prefixedProfileImage ? `${user.prefixedProfileImage}` : '/docSahab.jpg');
      }
      setIsCropping(false);
      setImageToCrop(null);
    } catch (error) {
      console.error('Error cropping or uploading image:', error);
      setIsCropping(false);
    }
  };

  const takeToProfile = () => {
    navigate(`/doctor-profile/${user.id}`);
  };

  const [title, setTitle] = useState(user?.tile ? user.tile : '');
  const [first, setFirst] = useState(user?.firstName ? user.firstName : '');
  const [last, setLast] = useState(user?.lastname ? user.lastname : '');
  const [list, setList] = useState(user?.professionList ? user.professionList.join(', ') : '');

  useEffect(() => {
    setTitle(user?.title);
    setFirst(user?.firstname);
    setLast(user?.lastname);
    setList(user?.professionList.join(', '));
  }, [user]);

  return (
    <>
      {isCropping && imageToCrop && (
        <div className="crop-modal">
          <div className="crop-container">
            <div className="cropper-container">
              <Cropper
                image={imageToCrop}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className="crop-controls">
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => setZoom(Number(e.target.value))}
                className="zoom-slider"
              />
              <div className="crop-buttons">
                <button onClick={() => setIsCropping(false)}>Cancel</button>
                <button onClick={handleCropConfirm}>Confirm</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex align-items-center p-3 bg-dark text-white">
        <div className="profile-image-container position-relative">
          <Image
            onClick={() => document.getElementById('profileImageInput').click()}
            src={profileImage}
            roundedCircle
            width="80"
            height="80"
            style={{ objectFit: 'cover', cursor: 'pointer' }} 
          />
          <div className="camera-icon-overlay">
            <FaCamera
              style={{ cursor: 'pointer' }}
              className="text-white"
              onClick={() => document.getElementById('profileImageInput').click()} 
            />
          </div>
          <input
            type="file"
            id="profileImageInput"
            accept="image/*"
            style={{ display: 'none' }} 
            onChange={handleImageChange} 
          />
        </div>
        <div className="ms-3">
          <h3 style={{ cursor: 'pointer' }} onClick={takeToProfile}>
            {`${title} ${first} ${last}`}
          </h3>
          <p className="mb-0">{`${list}`}</p>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;