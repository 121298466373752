import React from 'react';
import _ from 'lodash';
import utils from "../../../utils"
import { useLanguage } from '../../homepage/components/LanguageContext';
export default function SingleSelectComponent({
    name,
    arialabel,
    defaultValue,
    dataArAsObj,
    onChangeHandler,
    setFieldState,
    gender
}) {
    const { language } = useLanguage();
    
    function getLanguageLabel(item) {
        if (item.specialityId) {
            return utils.getLanguageGenderString(
                item.name,
                item.nameMaleDe,
                item.nameFemaleDe,
                language,
                gender
            );
        }
        return utils.getLanguageString(item.name, item.nameDe, language);
    }

    function getMyCompo() {
        if (dataArAsObj && !Array.isArray(dataArAsObj)) {
            return Object.keys(dataArAsObj).map(prop => (
                <option key={prop} value={prop}>
                    {dataArAsObj[prop]}
                </option>
            ));
        } 
        
        if (dataArAsObj && Array.isArray(dataArAsObj)) {
            return dataArAsObj.map(item => (
                <option key={item.id} value={item.id}>
                    {getLanguageLabel(item)}
                </option>
            ));
        }
        
        return null;
    }

    return (
        <select 
            name={name} 
            className="form-select" 
            aria-label={arialabel} 
            onChange={onChangeHandler}
            defaultValue={defaultValue}
        >
            <option value="">{defaultValue}</option>
            {getMyCompo()}
        </select>
    );
}