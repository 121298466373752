import React from 'react'
import { GoDotFill } from "react-icons/go";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import Footer from './Footer.jsx';
import { useLanguage } from './LanguageContext.js';

const AreYouDocView = ({
    ST,
    gotohome,
    takeToDocRegister,
}) => {
  const {language} = useLanguage();
  return (
    <div className='are-you-doc-container'>   
    <div className="container" style={{padding:'0px'}}>
    <div style={{margin:'0px'}} className="row ">
      <div style={{padding:'0px'}}  className="col-md-6 col-lg-6  position-relative text-center ">
        <IoArrowBackCircleOutline
          onClick={gotohome}
          style={{ fontSize: '80px', position: 'absolute', cursor: 'pointer' }}
        />
        <img className='img-fluid' width="450" height="1000" src="/steth.png" alt="Stethoscope" />
      </div>
      <div style={{textAlign: 'justify',textJustify: 'inter-word',padding:'12px '}} className = {language == 'English' ? "tp-txt col-md-6 col-lg-6" : "gp-tp-txt col-md-6 col-lg-6"}>
        <div className = {language == 'English' ? "text-white fw-bold tp-txt" : "text-white fw-bold gp-tp-txt"}
        >{ST.HEADERS.label}</div>


        <div 
          className = {language == 'English' ? "fw-bold lp-txt" : "fw-bold gp-lp-txt"}
        >{ST.HEADERS.label1}</div>


        <div className=" mb-4">
          <div className="d-flex align-items-start ">
            <GoDotFill size={30} className="go-dot-fill my-2" />
            <span 
              className = {language == 'English' ? "ps-3 custom-3 lp-txt" : "ps-3 custom-3 gp-lp-txt"}
            >{ST.HEADERS.label2}</span>
          </div>
          <div className="d-flex align-items-start ">
            <GoDotFill size={30} className="go-dot-fill my-2" />
            <span 
              className = {language == 'English' ? "ps-3 custom-3 lp-txt" : "ps-3 custom-3 gp-lp-txt"}
            >{ST.HEADERS.label3}</span>
          </div>
          <div className="d-flex align-items-start ">
            <GoDotFill size={30} className="go-dot-fill my-2" />
            <span 
              className = {language == 'English' ? "ps-3 custom-3 lp-txt" : "ps-3 custom-3 gp-lp-txt"}
            >{ST.HEADERS.label4}</span>
          </div>
          <div className="d-flex align-items-start ">
            <GoDotFill size={30} className="go-dot-fill my-2" />
            <span 
              className = {language == 'English' ? "ps-3 custom-3 lp-txt" : "ps-3 custom-3 gp-lp-txt"}
            >{ST.HEADERS.label5}</span>
          </div>
          
        </div>
        <div onClick={takeToDocRegister} className="d-flex dam">
          <button 
            className = {language == 'English' ? "btn custom-5 lp-txt" : "btn custom-5 gp-lp-txt"}
          >{ST.HEADERS.label6}</button>
        </div>
      </div>
    </div>
   
  </div>
  <Footer />
  </div>
  )
}

export default AreYouDocView