import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useLanguage } from '../../../homepage/components/LanguageContext';
import { useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { toast } from 'sonner';

const InsuranceInfo = () => {
  const { user,setUser } = useOutletContext();
  const { language } = useLanguage();
  const authUser = useAuthUser();
  const { langStrings } = useLanguage();
  const ST = langStrings.DOCEDIT.INSURANCEINFO;
  const [selectedInsurance, setSelectedInsurance] = useState([]);
  const [insuranceCompanyOptions, setInsuranceOptions] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  
  
  useEffect(() => {
    if (user.insuranceCompanyList && insuranceCompanyOptions.length > 0) {
      const initialSelected = insuranceCompanyOptions.filter(option => 
        user.insuranceCompanyList.includes(option.label)
      );
      setSelectedInsurance(initialSelected);
    }
  }, [user.insuranceCompanyList, insuranceCompanyOptions]);

  const fetchOptions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_JAVA_BASE_URL}/template`);
      const data = await response.json();
   
      const formattedOptions = data.insuranceCompany.map((company) => ({
        value: company.id,
        label: company.name,
      }));
      
      setInsuranceOptions(formattedOptions);
    } catch (error) {
      console.error(`Error fetching insurance companies: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updateData = {
      insuranceCompany: selectedInsurance.map(company => company.value).join(','),
    };

    try {
      toast.info(ST.UpdateInProccess, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      const LanguageLocale = language === 'English' ? 'ENGLISH' : 'GERMAN';
      const token = authUser()?.authToken || '';
      
      const response = await axios.put(
        `${process.env.REACT_APP_JAVA_BASE_URL}/doctor?doctorId=${user.id}`,
        updateData,
        {
          headers: {
            'X-LOCALE': LanguageLocale,
            'X-AUTHTOKEN': token
          },
        }
      );
      setTimeout(() => {
      if (response.status === 200) {
        toast.success(ST.DetailsUpdated, {
          position: 'top-center',
          duration: 3000,
          style: {
            fontSize: '15px', 
            padding: '10px 20px', 
            minWidth: '200px',
          },
        });
        setUser(response.data);
      }
    },300);
    } catch (err) {
      console.error('Failed to update insurance companies:', err);
      toast.warning(ST.FailedToUpdate, {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
    }
  };



  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formInsurance">
          <Form.Label>{ST.label}</Form.Label>
          <Select 
            options={insuranceCompanyOptions}
            value={selectedInsurance}
            isMulti
            placeholder="Select Insurance Companies"
            onChange={setSelectedInsurance}
            styles={{
              control: (base) => ({
                ...base,
                height: 'calc(1.5em + 0.75rem + 2px)',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem',
                fontSize: '1rem',
                width: '100%',
                paddingRight: '0.75rem',
                boxShadow: 'none',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }),
              input: (base) => ({
                ...base,
                fontSize: '1rem',
                color: '#495057',
              }),
              menu: (base) => ({
                ...base,
                zIndex: 1000,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '0.375rem',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? '#0d6efd' : 'white',
                color: state.isSelected ? 'white' : '#495057',
                ':hover': {
                  backgroundColor: '#e9ecef',
                },
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#e9ecef',
                borderRadius: '0.375rem',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem',
                padding: '0.25rem 0.5rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: '#495057',
                ':hover': {
                  backgroundColor: '#ced4da',
                  color: 'black',
                },
              }),
            }}
          />
        </Form.Group>
        
        <Button style={{ backgroundColor: '#f35b2a' }} type="submit">
          {ST.label2}
        </Button>
      </Form>
    </div>
  );
};

export default InsuranceInfo;