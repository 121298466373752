import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useLanguage } from './LanguageContext';
import NavBarList from '../../docsearch/components/NavBarList';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const CookieManagement = () => {
  const { language,langStrings, toggleLanguage } = useLanguage();  
  const ST = langStrings.DOCEDIT.COOKIECONSENT;
  const [cookiePreferences, setCookiePreferences] = useState(localStorage.getItem('cookieConsent') == 'accepted');
  const navigate = useNavigate();
  useEffect(() => {
    // const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
    // if (savedPreferences) setCookiePreferences(savedPreferences);
  }, []);

  const handleSavePreferences = () => {

    if (cookiePreferences) {
      localStorage.setItem('cookieConsent', 'accepted'); 
      Cookies.set('languageSelect',language)
      toast.success('Cookie Preferences are set', {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      navigate('/')
    } else {
      localStorage.setItem('cookieConsent', 'declined');
      if(language == 'German'){
        toggleLanguage();
      }
      Cookies.remove('languageSelect');
      toast.success('Cookie Preferences are set', {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      navigate('/')
    }

    // localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));

    // Cookies.set('_LOLZZ', 'auth_token_here', { expires: 7, sameSite: 'Strict' });

    // if (cookiePreferences.functional) {
    //   Cookies.set('languageSelect', language, { expires: 7 });
    // } else {
    //   Cookies.remove('languageSelect');
    // }
    // alert("Preferences saved!");
  };

  return (
    <div>
      <NavBarList />
      <div className="container text-dark my-4">
        <div className="row">
          <div className="col-md-8 offset-md-2 mt-5 mb-5">
            <h2 className="text-center my-4">{ST.label}</h2>
            <form onSubmit={(e) => {e.preventDefault();handleSavePreferences();}}className="p-4 border rounded shadow-sm bg-light">
              <div className="form-check mb-3">
              <p>{ST.label1}</p>
                <input type="checkbox" className="form-check-input" id="functionalCookies"
                  checked={cookiePreferences}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setCookiePreferences(isChecked);
                  }}
                />
                
                <label className="form-check-label" htmlFor="functionalCookies">
                {ST.label3}
                </label>
              </div>
              <button type="submit" onClick={handleSavePreferences} style={{backgroundColor:'#f35b2a',boxShadow:'none'}} className="btn btn-primary w-100 fw-bold ">
              {ST.label5}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookieManagement;
