import { useLanguage } from '../../homepage/components/LanguageContext';
import utils from './../../../utils';

const doctorDataGetUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_DOCTOR_DATA_GET_REL_URL;
const constBaseUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_SEARCH_API_REL_URL;

export  async function handleSubmit(e) {
  // Prevent the browser from reloading the page
  e.preventDefault();
  // Read the form data
  const form = e.target;
  const formData = new FormData(form);
  // Or you can work with it as a plain object:
  const formJson = Object.fromEntries(formData.entries());
 

  let appendUrl = utils.getQueryString(formJson);
  let url = constBaseUrl + appendUrl;

  
  let resp = fetchDoctorsHandler(url);
  return resp;
}

export default async function getDoctorsFromRelUrl(relUrl,language){
  let url = constBaseUrl + relUrl;
  let resp = fetchDoctorsHandler(url,language);
  return resp;
}

const fetchDoctorsHandler = async (url,language) => {
  const resp = await callSearchGetApi(url,language);
  let respJson = await resp.json();
  if (resp && resp.status === 200)
    return { success: true, respJson: respJson };
  else
    return { success: false, respJson: respJson };
}

const callSearchGetApi = async (url,language) => {
  const LanguageLocale = language == 'English' ? 'ENGLISH' : 'GERMAN';
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'X-LOCALE': `${LanguageLocale}`,
    },
  });
  let resp = await response;
  return resp;
}