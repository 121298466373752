import React, { useEffect, useState, useRef } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

const DemoComp = ({ handleMapClick }) => {
  const map = useMap();
  const [location, setLocation] = useState({
    lat: 47.80164,
    lng: 13.04541,
  });
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const initAutocomplete = () => {
      if (!window.google || !window.google.maps || !window.google.maps.places) {
        console.error('Google Maps Places library not loaded');
        return;
      }

      const input = document.getElementById('pac-input');
      if (!input) {
        console.error('Search input not found');
        return;
      }

      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ['geocode'],
        fields: ['geometry', 'formatted_address'],
      });

      autocompleteRef.current = autocomplete;

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          console.error('No geometry for this place');
          return;
        }

        const newLocation = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        setLocation(newLocation);
        if (map) {
          map.panTo(newLocation);
          map.setZoom(15);
        }
      });
    };

    if (map) {
      initAutocomplete();
    }
  }, [map]);

  

  return (
   
      <input
        id="pac-input"
        type="text"
        placeholder="Search location..."
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          width: '250px',
          height: '40px',
          padding: '0 12px',
          borderRadius: '4px',
          border: '1px solid #ccc',
          backgroundColor: 'white',
          zIndex: 1000,
          fontSize: '14px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
      />
    
  );
};

export default DemoComp;
